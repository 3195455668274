.loader-container {
    opacity: 1;
    transition: opacity 0.3s ease-out;
  }
  
  .loader-container.fade-out {
    opacity: 0;
  }
  
  .spinner-border {
    width: 3rem;
    height: 3rem;
  }