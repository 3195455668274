p strong 
{
    font-weight:600;
}

.speakers-wrpr.speaker-details-wrpr .speaker-name {
    color: #000;
}

.speakers-wrpr.speaker-details-wrpr p {
    color: #000;
}


.form-control.css-b62m3t-container.ageinput {
    border: none;
    padding: 0;
}
.dropdown-item.hover-blue:hover {
    background-color:#0d6efd!important;
    color: white !important;
    transition: background-color 0.3s ease;
  }

.ageinput .css-13cymwt-control {
    border: none;
    border-bottom: 1px solid #2f3685;
    border-radius: 0;
    padding-left: 0;
}

.ageinput .css-hlgwow {
    padding-left: 0;
}

.ageinput span.css-1u9des2-indicatorSeparator {
    display: none;
}

.flag-dropdown {
    background-color: transparent !important;
    border: none !important;
}

.phoneinput.special-label input
{
    border:none;
}
.filter-wrprspeaker {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e")!important;
    background-repeat:no-repeat;
    background-position: right .75rem center;
    background-size: 16px 12px;
    background-color:#f2f1f1;
}
.dropdown-item.hover-blue:hover {
    background-color:#0d6efd!important;
    color: white !important;
    transition: background-color 0.3s ease;
  }

  button.dropdown-item.hover-blue {
    font-size: 0.875rem;
}
