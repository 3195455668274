@font-face {
    font-family: 'icons-sm';
    src:  url('fonts/icons-sm.eot?ms3c7d');
    src:  url('fonts/icons-sm.eot?ms3c7d#iefix') format('embedded-opentype'),
      url('fonts/icons-sm.ttf?ms3c7d') format('truetype'),
      url('fonts/icons-sm.woff?ms3c7d') format('woff'),
      url('fonts/icons-sm.svg?ms3c7d#icons-sm') format('svg');
    font-weight: normal;
    font-style: normal;
  } 
  [class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icons-sm' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
  
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  .swiper-rightarrow {
    position: absolute;
    right: 0;
    top: 218px;
}


.react-tel-input .form-control {
    background: transparent;
    border: 1px solid #cacaca;
    border-radius: 5px;
    font-size: 14px;
    height: 35px;
    letter-spacing: .01rem;
    line-height: 25px;
    margin-bottom: 0 !important;
    margin-left: 0;
    margin-top: 0 !important;
    outline: none;
    padding-left: 48px;
    position: relative;
    width: 100%;
}

.phoneinput, .phoneinput.special-label input {
    border: none;
    border-bottom: 0px solid #2f3685;
    border-radius: 0;
    padding-left: 0;
}
  
  
   body, img,  .readmore, .header-nav a, .mega-sub-menu .mega-menu-link, .book-an-appointment {
      font-family: "Anek Malayalam", sans-serif !important;
      transition: all .2s linear !important;
      -o-transition: all .2s linear !important;
      -moz-transition: all .2s linear !important;
      -webkit-transition: all .2s linear !important;
   }
   
  
   
   h1,h2,h3,h4,h5,h6,h7, .navbar, .menu-item a, .menu-headermenu-container .submenu{
       font-family: "Anek Malayalam", sans-serif;
      
   }
    h1,h2,h3,h4,h5,h6,h7{
         font-family: "Anek Malayalam", sans-serif;
         font-weight:normal;
    }
  h1{
      font-size:45px;
      font-weight:600;
      line-height: 48px;
      color:#fff;
  }
  img{
      max-width:100%;
  }
  
  a:hover{
      text-decoration:none;
  }
  a.navbar-toggler{
      border:none;
  }
  a.navbar-toggler .icon-menu1 {
      font-size: 32px;
  }
  
  .phoneonly{
      display:none !important;
  }
  
  .desktoponly{
      display:flex;
  }
  
  .btn-groupregister.desktoponly {
    display: flex !important;
    align-items: center;
    justify-content: center;
}

.btn-groupregister.desktoponly i {
    margin-left: 8px;
}
  
  .navbar-brand img {
      max-width: 180px;
  }
  .menu-headermenu-container li {
     margin:0 5px;
  }
  
  .menu-headermenu-container li a {
      text-decoration: none;
      color: #000;
      display:inline-flex;
      line-height:36px;
      padding:0 25px;
      border-radius:30px;
      font-size: 14px;
  }
  .navbar>.container-fluid.menu-wrpr {
      /*align-items: flex-end;*/
  }
  .dropdown:hover .dropdown-menu {
      display: block;
  }
  .dropdown .dropdown-menu {
      transition: all 0.5s;
      overflow: hidden;
      transform-origin: center center;
      opacity: 0;
      display: block;
      z-index: -100 !important;
      visibility: hidden;
      right:0px;
  }
  .dropdown:hover .dropdown-menu {
  
      opacity: 1;
      box-shadow: 0 11px 10px rgb(0 0 0 / 18%);
      transform: translateY(10px);
      visibility: visible;
      z-index: 100 !important;
  }
  .contact a,
  .login a {
      background: #2b5e92 !important;
      color:#fff !important;
      
  }
  
  .navbar {
      font-size: 16px;
      min-height:80px;
  }
  
  .navbar a {
      color: #000;
  }
  
  .navbar .nav-item + .nav-item {
      margin-left: 15px;
  }
  
  
  /* site */
  .swiper-rightarrow {
    position: absolute;
    right: 0;
    top: 218px;
}


.react-tel-input .form-control {
    background: transparent;
    border: 1px solid #cacaca;
    border-radius: 5px;
    font-size: 14px;
    height: 35px;
    letter-spacing: .01rem;
    line-height: 25px;
    margin-bottom: 0 !important;
    margin-left: 0;
    margin-top: 0 !important;
    outline: none;
    padding-left: 48px;
    position: relative;
    width: 100%;
}

.phoneinput, .phoneinput.special-label input {
    border: none;
    border-bottom: 0px solid #2f3685;
    border-radius: 0;
    padding-left: 0;
}
   
  
  /* banner */
  .banner h1 {
      font-size:39px;
  }
  .banner-cntnt {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: 1;
      background: rgb(0 0 0 / 60%);
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding-bottom: 70px;
      flex-direction: column;
  }
  
  .banner {
      position: relative;
  }
  .banner img {
      height: 600px;
      width: 100%;
      object-fit: cover;
  }
  .banner h1 span {
      background: #0089d1;
      display: inline-flex;
      padding: 3px 5px;
      border-radius: 8px;
      position:relative;
  }
  .banner h1 span:before {
      content:"";
      position:absolute;
      display:inline-flex;
      width:127px;
      height:38px;
      /* background:url(../images/logo-waves.png) no-repeat; */
      top: 43px;
  }
  
  .demo-btn-wrpr {
      display: flex;
      margin-top: 33px;
  }
  
  .demo-btn-wrpr .btn-solmarine ,
  .contact-form .btn-solmarine {
      line-height: 46px;
      padding: 0 32px;
      border: 3px solid #00c5be;
      font-size: 14px;
      text-transform: uppercase;
      letter-spacing: 1px;
  }
  
  /* banner end */
  
  
  
  
  
  
  
  
  
  
  /* footer-section */
  
  .footer-section {
      padding-top: 90px;
      padding-bottom: 90px;
      background:#273786;
  }
  
  .footer-logo-cntnr {
      max-width: 200px;
      text-align: center;
  }
  
  .footer-logo-cntnr h2 {
      font-size: 17px;
      color: rgb(23 27 36 / 77%);
  }
  .social-media a {
      background: #07254f;
      display: inline-flex;
      width: 29px;
      height: 29px;
      justify-content: center;
      align-items: center;
      color: #fff;
      text-decoration: none;
      font-size: 13px;
  }
  .social-media {
      margin-top: 21px;
  }
  
  .footer-menu-cntnr {
      display: flex;
      flex-direction: column;
  }
  
  .footer-menu-cntnr a {
      font-size: 14px;
      color: #000;
      text-decoration: none;
      margin-bottom: 10px;
  }
  
  .footer-btn-cntnr a {
      line-height: 46px;
      padding: 0 32px;
      border: 3px solid #00c5be;
      font-size: 14px;
      text-transform: uppercase;
      letter-spacing: 1px;
      min-width: 210px;
      margin-bottom: 10px;
  }
  
  .footer-btn-cntnr a:last-child {
      background: #00a1c9;
  }
  
  /* footer-section end */
  
  
  
  /* Header menu animation */
  .nav-item {
      display: flex;
      align-items: center;
  }
  
  .headernav  .dot {
      width: 40px;
      height: 2px;
      background: #00c5be;
      opacity: 0;
      -webkit-transform: translateX(30px);
      transform: translateX(30px);
      -webkit-transition: all 0.2s ease-in-out;
      transition: all 0.2s ease-in-out;
      position: absolute;
      margin-left: -21px;
      top: 60px;
  }
  
  .headernav  li:nth-child(1):hover ~ .dot {
    -webkit-transform: translateX(30px);
    transform: translateX(30px);
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    opacity: 1;
  }
  
  .headernav  li:nth-child(2):hover ~ .dot {
    -webkit-transform: translateX(110px);
      transform: translateX(110px);
      -webkit-transition: all 0.2s ease-in-out;
      transition: all 0.2s ease-in-out;
      opacity: 1;
      width: 68px;
      margin-left: -28px;
  }
  
  .headernav  li:nth-child(3):hover ~ .dot {
    -webkit-transform: translateX(200px);
    transform: translateX(200px);
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    opacity: 1;
    margin-left: -20px;
    width: 44px;
  }
  
  .headernav  li:nth-child(4):hover ~ .dot {
    -webkit-transform: translateX(285px);
    transform: translateX(285px);
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    opacity: 1;
    margin-left: -30px;
    width: 88px;
  }
  .headernav  li:nth-child(5):hover ~ .dot {
    -webkit-transform: translateX(385px);
    transform: translateX(385px);
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    opacity: 1;
    margin-left: -10px;
    width: 50px;
  }
  
  .contactus, .reqdemo {
      border-radius: 30px;
      padding-left: 31px !important;
      padding-right: 31px !important;
      color: #fff !important;
      line-height: 13px;
  }
  
  .reqdemo {
      background: #ea222b;
      box-shadow: 0 0 0 5px #ea222b;
	  /*animation: fadeIn 5s;
	  animation-fill-mode: forwards;
	  animation-iteration-count: infinite;*/
  }
  @keyframes fadeIn {
	0% { background: #ea222b;  }
	50% { background: #20234c;  }
	100% { background: #ea222b;  }
}
  .contactus:hover, .reqdemo:hover {
      border: 0px solid #212529;
  }
  
  .navbar.whiteNav {
      background: #fff;
  }
  p{
      font-size:17px;
	  font-weight: 300;
	  letter-spacing:.5px;
  }
  
  .section-btn-wrpr {
      display: flex;
      justify-content: center;
      align-items: center;
  }
  
  .section-btn-wrpr .btn-dark {
      line-height: 34px;
      padding: 0 10px;
      border-radius: 30px;
      font-size: 17px;
      margin: 0 4px;
      min-width: 139px;
  }
  
  .btn-dark.btn-yellow, .btn-dark.btn-red {
      background: #f9cc26 !important;
      border: none;
      color: #000;
      margin-top: 30px;
  }
  
  .btn-dark.btn-red {
      background: #e13231;
      color: #fff;
  }
  
  /* Header menu animation end */
  
  
  
  
  /* about us */
  
  .aboutus-wrpr {
      background: url(../images/aboutus-bg-pic.jpg) no-repeat center 72%;
      background-size: cover;
      min-height: 340px;
      display: flex;
      align-items: center;
  }
  
  .aboutus-wrpr .btn-dark.btn-yellow:hover {
      color: black;
      box-shadow: 0 0 20px #f3ce35;
      background: #f3ce35;
  }
  .aboutus-wrpr .btn-dark.btn-red:hover {
      color: white;
      box-shadow: 0 0 20px #FF5722;
      background: #FF5722;
  }
  
  
  /* about us end */
  
  /* speakers */
  .swiper-container.speakers-slide img {
      /*width: 127px;*/
      width: 150px;
      height: 150px;
      border-radius: 50%;
  }
  .speakers-wrpr h2 {
      text-transform: uppercase;
      font-weight: 600;
  }
  
  .speakers-wrpr {
      padding: 40px;
	  background: color(srgb 0.38 0.49 0.55 / 0.03);
  }
  .speaker-pattern {
      background: url(../images/pattern-for-partners.png);
      display: inline-flex;
      width: 124px;
      height: 26px;
      margin: 0 50px;
      background-size: cover;
      top: 4px;
      position: relative;
  }
  .speaker-name {
      text-align: center;
      font-size: 18px;
      font-weight: 700;
      margin-top: 30px;
  }
  
  .speaker-designation {
      text-align: center;
      font-size: 14px;
  }
  .swiper-pagination-wrpr {
      background: red;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 45px;
  }
  
  .swiper-pagination-bullet {
      width: 15px;
      height: 15px;
      margin: 4px;
  }
  
  .swiper-button-prev {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%234c71ae'%2F%3E%3C%2Fsvg%3E") !important;
    background-size:23px 44px;
    background-repeat: no-repeat !important;
    color: transparent !important;
  }
  
  .swiper-button-next {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%234c71ae'%2F%3E%3C%2Fsvg%3E") !important;
    background-size:23px 44px;
    background-repeat: no-repeat !important;
    color: transparent !important;
  }
  .swiper-pagination-wrpr .swiper-pagination-bullet-active {
      background:#f9cd26;
  }
  
  /* speakers end*/
  
  
  /* sessions */
  
  
  .sessions-wrpr h2 {
      text-transform: uppercase;
      font-weight: 400;
  }
  
  
  .card.session-card {
		background: #f2f1f1;
		border: none;
		border-radius: 10px;
		padding: 0;
		display: flex;
		align-items: flex-start;
		margin-top: 0px;
		margin-bottom: 30px;
  }
  
  .card.session-card .scard-header {
    font-size: 19px;
    font-weight: 600;
    text-align: left;
    text-transform: uppercase;
    padding: 5px;
    padding-top: 8px;
    padding-left: 8px;
    padding-right: 8px;
    width: 100%;
    min-height: 40px;
    display: flex;
    align-items: center;
    border: none;
  }
  
  .card.session-card.yellow  .scard-header {
      background: url(../images/session-card-shape-yellow.png) no-repeat;
      background-size: 60px;
  }
  .card.session-card.red  .scard-header {
      background: url(../images/session-card-shape-red.png) no-repeat;
      background-size: 60px;
  }
  .card.session-card.blue  .scard-header {
      background: url(../images/session-card-shape-blue.png) no-repeat;
      background-size: 60px;
  }
  .card.session-card.orange  .scard-header {
      background: url(../images/session-card-shape-orange.png) no-repeat;
      background-size: 60px;
  }
  .card.session-card.green  .scard-header {
      background: url(../images/session-card-shape-green.png) no-repeat;
      background-size: 60px;
  }
  .card.session-card.purple  .scard-header {
      background: url(../images/session-card-shape-purple.png) no-repeat;
      background-size: 60px;
  }
  
  .card.session-card.yellow .scard-header div {
      line-height: 23px;
      color: #f9cd26;
  }
  .card.session-card.red .scard-header div {
      line-height: 23px;
      color: #ea222a;
  }
  .card.session-card.blue .scard-header div {
      line-height: 23px;
      color: #4aa6df;
  }
  .card.session-card.orange .scard-header div {
      line-height: 23px;
      color: #f48020;
  }
  .card.session-card.green .scard-header div {
      line-height: 23px;
      color: #11a550;
  }
  .card.session-card.purple .scard-header div {
      line-height: 23px;
      color: #80529f;
  }
  
  .scard-heading h2 {
      font-size: 18px;
      text-transform: none;
      text-align: left;
	  min-height:43px;
  }
   .sessions-new .scard-heading h2 {
		min-height:unset;
	}
  
  .session-type-label {
    background: #f9cd26;
    color: #000;
    font-size: 12px;
    padding: 0px 8px;
    padding-top: 2px;
    border-radius: 5px;
    display: inline-flex;
    min-width: 92px;
    line-height: 22px;
    justify-content: center;

  }
  
  .card.session-card.yellow .session-type-label {
      background: #f9cd26;
  }
  .card.session-card.red .session-type-label {
      background: #ea222a;
  }
  .card.session-card.blue .session-type-label {
      background: #4aa6df;
  }
  .card.session-card.orange .session-type-label {
      background: #f48020;
  }
  .card.session-card.green .session-type-label {
      background: #11a550;
  }
  .card.session-card.purple .session-type-label {
      background: #80529f;
  }
  
  .session-members {
      text-align: left;
      font-size: 13px;
      margin-top: 14px;

  }
  
  .session-members li {
      margin-left: 27px;
      padding-left: 0px !important;
	  font-weight: 500; 
      font-size: 15px;
  }
  
  .session-members ul {
		padding-left: 0px;
		display: flex;
		flex-wrap: wrap;
		position: relative;
		left: -11px;
		max-height: 61px;
		overflow: hidden;
		overflow-y:auto;
		align-content: baseline; 
		 
	  }
	 .card.session-card {
			transition: all .2s linear !important;
		  -o-transition: all .2s linear !important;
		  -moz-transition: all .2s linear !important;
		  -webkit-transition: all .2s linear !important;
		box-shadow: 0 0 30px color(srgb 0 0 0 / 0);	 
		transform: translate(0px, 5px);
	}
	 .card.session-card:hover {
		box-shadow: 0 0 30px color(srgb 0 0 0 / 0.2);
		transform: translate(0px, 0px);
	}

	  .session-members ul::-webkit-scrollbar {
		width: 5px;
	}
	 
	  .session-members ul::-webkit-scrollbar-track {
		-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	}
	 
	  .session-members ul::-webkit-scrollbar-thumb {
	  background-color: darkgrey;
	  outline: 1px solid slategrey;
	}
	.session-members.speakers ul {
		min-height: 61px;
	}
  
  .session-members ul li::marker {
    color: #f9cd26;
  }
  
  .sessions-tabs .nav.nav-tabs .nav-item:nth-child(1) a.nav-link:hover {
    
	box-shadow:0px 0 0 3px #ffffff ;
}
.sessions-tabs .nav.nav-tabs .nav-item:nth-child(2) a.nav-link:hover {
    
	box-shadow:0px 0 0 3px #ffffff ;
}

.sessions-tabs .nav.nav-tabs .nav-item:nth-child(3) a.nav-link:hover {
    box-shadow:0px 0 0 3px #ffffff ;
}

.sessions-tabs .nav.nav-tabs .nav-item:nth-child(4) a.nav-link:hover {
    box-shadow:0px 0 0 3px #ffffff ;
}

.sessions-tabs .nav.nav-tabs .nav-item:nth-child(5) a.nav-link:hover {
    box-shadow:0px 0 0 3px #ffffff ;
}
  
  .card.session-card.yellow .session-members ul li::marker {
    color: #f9cd26;
  }
  .card.session-card.red .session-members ul li::marker {
    color: #ea222a;
  }
  .card.session-card.blue .session-members ul li::marker {
    color: #4aa6df;
  }
  .card.session-card.orange .session-members ul li::marker {
    color: #f48020;
  }
  .card.session-card.green .session-members ul li::marker {
    color: #11a550;
  }
  .card.session-card.purple .session-members ul li::marker {
    color: #80529f;
  }
  
  .sponsor-logo-ruppe-icon {
    display: flex;
    font-size: 14px;
    width: 100%;
    justify-content: center;
	text-align:left;
    align-items: center;	
  }
  
  .sponsor-logo-ruppe-icon .icon-ruppee {
      display: inline-flex;
      width: 16px;
      height: 13px;
      background: url(../images/ruppe-icon.png) no-repeat;
      background-size: cover;
  }
  
  .date-and-time {
        width: 100%;
    /*background:  url(../images/pattern-for-counter-section.png) no-repeat 5px 5px, url(../images/pattern-for-counter-section-2.png) no-repeat 98.5% 5px ; */
	background-color:#0a2b8d;
	margin: 0 auto;
    border-radius: 10px;
    font-size: 13px;
    padding: 2px;
    padding-left: 10px;
    padding-right: 10px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0;
    color: #fff;
    display: flex;
    justify-content: space-between;
    background-size: 38px;
	min-height:46px;
  }
  .card.session-card.yellow  .date-and-time {
      background: #f9cd26;
  }
  
  .card.session-card.red  .date-and-time {
      background: #ea222a;
      color:#fff;
  }
  
  .card.session-card.blue  .date-and-time {
      background: #4aa6df;
      color:#fff;
  }
  .card.session-card.orange  .date-and-time {
      background: #f48020;
      color:#fff;
  }
  .card.session-card.green  .date-and-time {
      background: #11a550;
      color:#fff;
  }
  .card.session-card.purple  .date-and-time {
      background: #80529f;
      color:#fff;
  }
  
  .date-and-time .date span {
      display: inline-flex;
      width: 9px;
      height: 10px;
      background: url(../images/calendar-icon.png) no-repeat;
      background-size: cover;
      margin-right: 5px;
  }
  
  .card.session-card.purple .date-and-time .date span ,
  .card.session-card.green .date-and-time .date span ,
  .card.session-card.red .date-and-time .date span ,
  .card.session-card.orange .date-and-time .date span ,
  .card.session-card.blue .date-and-time .date span {
      background: url(../images/calendar-icon-white.png) no-repeat;
      background-size: cover;
  }
  
  .date-and-time .time span {
      display: inline-flex;
      width: 9px;
      height: 9px;
      background: url(../images/time-icon.png) no-repeat;
      background-size: cover;
      margin-right: 5px;
  }
  
  .card.session-card.purple .date-and-time .time span ,
  .card.session-card.green .date-and-time .time span ,
  .card.session-card.red .date-and-time .time span ,
  .card.session-card.orange .date-and-time .time span ,
  .card.session-card.blue .date-and-time .time span {
      background: url(../images/time-icon-white.png) no-repeat;
      background-size: cover;
  }
  
  .swiper-container.sessions-slide {
      margin-left: 50px;
      margin-right: 50px;
      padding-left: 7px;
      padding-right: 7px;
      border-radius: 60px;
  }
  
  .swiper-leftarrow {
      position: relative;
      top: 201px;
  }
  .swiper-rightarrow {
      position: absolute;
      top: 201px;
      right: 0;
  }
  .vbox-title{
	  font-size:17px;
  }
  
  
  /* NEWS */
  
  .news-wrpr h2 {
      text-transform: uppercase;
      font-weight: 600;
  }
  
  .news-slide img {
      width: 100%;
  }
  
  .news-slide h2 {
      font-size: 17px;
      text-transform: unset;
      margin-top: 17px;
      text-align: left;
  }
  
  .news-slide p {
      font-size: 16px;
      text-align: left;
  }
  
  .news-card {
      border: none !important;
      background:none !important;
  }
  .news-wrpr {
      background: #fffef7;
      padding: 48px 0;
  }
  
  .swiper-container.news-slide {
      margin-left: 50px;
      margin-right: 50px;
      padding-left: 7px;
      padding-right: 7px;
  }
  .news-wrpr .swiper-rightarrow {
		top: 218px;
	}
  
  /* NEWS End*/
  
  
  /* photogallery */
  
  .photogallery-wrpr h2 {
      text-transform: uppercase;
      font-weight: 600;
  }
  
  .photogallery-wrpr {
      padding-top: 48px;
	  padding-bottom: 48px;
  }
  
  .photogallery-wrpr .swiper-rightarrow {
      top: 124px;
  }
  
  .photogallery-wrpr .swiper-leftarrow {
      top: 107px;
  }
  
  
  /* photogallery End*/
  
  
  
  /* KLF Counts */
  
  .klf-counts-wrpr {
      padding: 100px 0;
      background: #273786;
      /*zoom: .8;*/
  }
  
  .counter-cntnr {
      background: #304ea0;
      width: 148px;
      height: 148px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      border-radius: 50%;
  }
  
  .counter-cntnr .counter.plus {
      color: #fff;
      font-size: 40px;
      font-weight: 500;
      line-height: 34px;
  }
  .counter-cntnr.count1 .counter:after {
      content: "+";
  }
  
  .counter-cntnr .counter-text {
      color: #fff;
      text-transform: uppercase;
      font-size: 13px;
      letter-spacing: 1px;
  }
  
  #counters_2 .col-md-4 {
      display: flex;
      justify-content: center;
  }
  .counter-cntnr.count1.lastcount .counter:before {
      content: "0";
  }
  
  
  
  /* KLF Counts end */
  
  
  
  /* festival partners */
  
  
  .festival-partners-wrpr h2 {
      text-transform: uppercase;
      font-weight: 600;
  }
  .festival-partners-wrpr {
      padding: 46px 0;
  }
  
  .festival-partners-wrpr .col-md-12 .row {
      margin-top: 50px;
	  justify-content: center;
  }
  
  .festival-partners-wrpr .col-md-12 .col-md-2,
  .festival-partners-wrpr .col-md-12 .col-md-3 {
    min-height: 110px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 220px;
    border: 1px solid #dadada;
    margin: 4px;
  }
  
  
  /* festival partners end */
  
  
  /*  testinomials  */
  
  
  .testimonials-wrpr {
      background: #fffef7;
      padding: 48px 0;
      position: relative;
      top: 11px;
  }
  .testimonials-wrpr h2 {
      text-transform: uppercase;
      font-weight: 600;
  }
  
  .testim-user {
      background: url(../images/tesimonail_yellow-shape.jpg) no-repeat 100% 0;
      min-height: 208px;
      background-size: 60%;
      max-width: 400px;
      display: flex;
      justify-content: center;
      align-items: flex-end;
  }

  .testim-user img {
    width: 100%;
    min-height: 407px;
    object-fit: cover;
    object-position: center;
}
  .testimonial-card.red .testim-user {
      background: url(../images/tesimonail_red-shape.jpg) no-repeat 100% 0;
      background-size: 60%;
  }
  .testimonial-card.green .testim-user {
      background: url(../images/tesimonail_green-shape.jpg) no-repeat 100% 0;
      background-size: 60%;
  }
  .testimonial-card.orange .testim-user {
      background: url(../images/tesimonail_orange-shape.jpg) no-repeat 100% 0;
      background-size: 60%;
  }
  .testimonial-card.purple .testim-user {
      background: url(../images/tesimonail_purple-shape.jpg) no-repeat 100% 0;
      background-size: 60%;
  }
  .card.testimonial-card.red .testim-cntnt {
      background: #e03232;
  }
  .card.testimonial-card.green .testim-cntnt {
      background: #11a550;
  }
  .card.testimonial-card.orange .testim-cntnt {
      background: #f48020;
  }
  .card.testimonial-card.purple .testim-cntnt {
      background: #80529f;
  }
  
  .testim-user-name {
      text-align: right;
      font-weight: 700;
      color: #fff;
  }
  
  .testim-user-desig {
      text-align: right;
      font-size: 11px;
      color: #fff;
  }
  .swiper-container.testimonials-slide .swiper-slide {
      display: flex;
      justify-content: center;
  }
  
  
  .card.testimonial-card .testim-cntnt {
      max-width: 400px;
      background: #f9cc26;
      padding: 30px;
      text-align: left;
      color: #fff;
  }
  .card.testimonial-card {
      max-width: 400px;
      border: none;
  }
  .testim-cntnt p {
      color: #fff;
  }
  .testimonials-wrpr .swiper-rightarrow {
		top: 226px;
	}
  
  
  
  /*  testinomials  */
  
  
  
  
  
  
  
  
  
  
  
  
  /* footer menu animation */
  
  .footer-menu-cntnr a{position:relative;}
  .footer-menu-cntnr a:before {
      content: "";
      position: absolute;
      top: 2px;
      left: 0;
      width: 3px;
      height: 14px;
      background: #00a1c9;
      opacity: 0;
      transition: all .2s linear;
      -o-transition: all .2s linear;
      -moz-transition: all .2s linear;
      -webkit-transition: all .2s linear;
  }
  
  .footer-menu-cntnr a:hover:before {
      left: -14px;
      opacity: 1;
  }
  
  
  
  /* footer menu animation end */
  
  
  
  /*footer copy */
  
  .footer-copy .col-md-12 {
      font-size: 13px;
      position: relative;
  }
  
  .footer-copy {
      padding-bottom: 40px;
      background:#2f3685;
  }
  
  .footer-copy .col-md-12:before {
      position: absolute;
      top: 10px;
      left: 0;
      right: 0;
      content: "";
      height: 1px;
      background: #424bb2;
  }
  
  .footer-copy .col-md-12 span {
      background: #2f3685;
      position: relative;
      z-index: 1;
      padding-left: 10px;
      padding-right: 10px;
      color: #fff;
  }
  .reqdemo:hover {
      background: red;
	  box-shadow: 0 0 0 5px red;
  }
  .nav-link:focus, .nav-link:hover {
      color: red;
  }
  
  /*footer copy end*/
  
  
  /* Modal */
  
  #myModal input {
      margin-bottom: 10px;
      border: none;
      height: 47px;
      background: #fff;
      color: #000;
      border-bottom: 1px solid #dadada;
      border-radius: 0px;
      padding-left: 0px;
  }
  
  #myModal label {
      font-size: 13px;
      color: #fff;
  }
  #myModal .modal-header{
      border: none;
      padding: 15px 25px 0 25px;
      color: #fff;
  }
  #myModal textarea {
      min-height: 120px;
      margin-bottom: 17px;
      background: #171b24;
      color: #fff;
  }
  #myModal .speaker-pattern {
      position: relative;
      top: 24px;
      right: -46px;
      width: 60px;
  }
  
  
  #myModal .modal-content {
      /* background: url(../images/dot-waves.png) no-repeat left 768%, linear-gradient(45deg, #00a0ca, #00c5be); */
      border: none;
      padding:20px;
      box-shadow: 0 0 150px rgb(0 0 0 / 52%);
  }
  
  #myModal  select {
      border-radius: 0;
      margin-bottom: 29px;
      border: none;
      border-bottom: 1px solid #dadada;
      height: 47px;
      background: #fff;
      color: #000;
  }
  
  #request-form {
      line-height: 46px;
      padding: 0 32px;
      border: 3px solid #00c5be;
      font-size: 14px;
      text-transform: uppercase;
      letter-spacing: 1px;
      min-width: 210px;
      margin-bottom: 10px;
  }
  
  /* Modal end */
  
  
  .mailto a {
      text-decoration: none;
      color: #000;
      font-size: 15px;
  }
  
  .mailto a i {
      font-size: 21px;
      position: relative;
      top: 4px;
      margin-right: 6px;
  }
  
  
  
  
  address {
      font-size: 15px;
      padding-left: 30px;
      margin-top: 19px;
      position: relative;
      margin-bottom: 104px;	
  }
  
  address:before {
      content: "\ec97";
      position: absolute;
      left: 0px;
      font-family: 'icons-sm';
      font-size: 20px;
      top: -3px;
  }
  .footer-menu-wrpr {
      display: flex;
      justify-content: center;
      margin-bottom: 80px;
  }
  
  .footer-menu-wrpr a {
      text-decoration: none;
      color: #fff;
      font-size: 18px;
      text-transform: uppercase;
      margin: 0 20px;
  }
  
  .register-social-wrpr {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 80px;	
  }
  
  .register-social-wrpr .btn-register {
      background: red;
      border: none;
      border-radius: 30px;
      min-width: 110px;
      font-weight: 500;
      letter-spacing: .5px;
      margin: 0 3px;
  }
  
  .register-social-wrpr  a img {
      max-width: 34px;
      margin: 0 4px;
  }
  .footer-logo-wrpr img {
      /*max-width: 481px;*/
      max-width: 421px;
      height:auto;
  }
  
  .footer-cntnt-wrpr {
      color: #fff;
  }
  
  .footer-cntnt-wrpr h4 {
      font-weight: 500;
  }
  
  .footer-cntnt-wrpr p {
      font-size: 16px;
  }
  .footer-cntnt-wrpr h4 {
      font-size: 20px;
  }
  .footer-logo-wrpr {
      display: flex;
      justify-content: center;
  }
  .footer-menu-wrpr a:hover {
      color: #f42717;
  }
  .register-social-wrpr .btn-register:hover {
      box-shadow: 0 0 20px #ea3723;
  }
  .modal.register-popup button.btn-primary {
      border: none;
      min-width: 133px;
      font-weight: 600;
      text-transform: uppercase;
      margin-top: 30px !important;
      background: #2da554;
      border-radius: 4px;
  }
  #myModal .modal-content {
      background:  linear-gradient(45deg, #00a0ca, #00c5be);
      border: none;
      padding: 20px 0 0 0;
      box-shadow: 0 0 150px rgb(0 0 0 / 52%);
  }
  
  .modal.register-popup .modal-body {
      background: #fff;
      border-radius: 4px;
      margin-top: 31px;
  }
  #myModal button.close {
      background: none;
      border: none;
      font-size: 42px;
      position: absolute;
      right: 21px;
  }
  
  /* Deligate registration */
  
  
  .reqdemo.deligate-registration {
      background: #80529f;
  }
  
  #deligateRegistration .modal-title {
      color: #273786;
      
  }
  
  #deligateRegistration .modal-title span{
      background: url(../images/pattern-for-partners.png) no-repeat;
      display: inline-flex;
      min-width: 43px;
      height: 19px;
      background-size: 85px;
      position: relative;
      top: 2px;
  }
  #deligateRegistration .modal-body {
      background: #273786;
      margin-left: 12px;
      margin-right: 12px;
      margin-top: 0;
      padding-top:30px;
  }
  
  #deligateRegistration .modal-header {
      min-height: 87px;
      border: none;
  }
  
  .form-item label{
    position: absolute;
      cursor: text;
      z-index: 2;
      top: 13px;
      left: 13px;
      font-size: 12px;
      font-weight: normal;
      background: #273786;
      padding: 0 10px 0 0;
      color: #fff;
      transition: all .3s ease;
  }
  .form-item input:focus + label,
  .form-item input:valid + label{
    font-size: 12px;
    top: -10px;
    letter-spacing: 1px;
  }
  .form-item{
    position: relative;
    margin-bottom: 15px
  }
  #deligateRegistration .form-control {
      background: transparent;
      border: none;
      border-bottom: 1px solid #fff;
      border-radius: 0px;
      color:#fff;
      padding-left:0px;
  }
  #deligateRegistration .form-control:focus{
      box-shadow:none;
  }
  .form-item.form-item-select label,
  .form-item.form-item-phone label{
      top: -10px;
      font-size: 12px;
      letter-spacing:1px;
  }
  .form-item.form-item-date label {
      top: -10px;
      font-size: 12px;
      letter-spacing:1px;
  }
  #deligateRegistration.modal.register-popup button.btn-primary{
      margin-top:0px !important;
      background: #80529f;
  }
  
  button.close {
      border: none;
      background: transparent;
      font-size: 38px;
      font-weight: 100;
  }
  #deligateRegistration .form-item-phone input.form-control {
      padding-left:52px;
  }
  
  .intl-tel-input.allow-dropdown {
      width: 100%;
  }
  .form-item.form-item-phone label {
      left: 0px;
  }
  
  .intl-tel-input .selected-flag {
      /*padding-left: 0px;*/
  }
  
  /* Deligate registration end */
  
  
  .data-security {
      padding-left: 17px;
      font-size: 13px;
  }
  
  .data-security p {
      font-size: 12px;
      position: relative;
      top: -4px;
  }
  
  

  .intl-tel-input {
    display: table-cell;
  }
  .intl-tel-input .selected-flag {
    z-index: 4;
  }
  .intl-tel-input .country-list {
    z-index: 5;
  }
  .input-group .intl-tel-input .form-control {
    border-top-left-radius: 0px;
    border-top-right-radius: 0;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0;
  }
  .data-security {
      display: flex;
  }
  
  .data-security i {
      font-size: 18px;
      position: relative;
      top: 1px;
      margin-right: 8px;
      color: #7f539e;
  }
  
  
  /* select registration type */
  
  
  #registrationType .modal-content {
      border: none;
      border-radius: 0px;
  }
  
  #registrationType .modal-content .popup-columns .col-md-6:nth-child(2) ,
  #registrationType .modal-content .popup-columns .col-md-4:nth-child(2) {
      background: #2f3584;
      padding: 45px;
  }
  
  #registrationType .modal-content .popup-columns .col-md-6:nth-child(4),
  #registrationType .modal-content .popup-columns .col-md-4:nth-child(4) {
      background: #ffcb08;
      padding: 45px;
  }
  
  #registrationType .modal-content .popup-columns .col-md-4:nth-child(5) {
      background: #e48735;
      padding: 45px;
  }
  
  #registrationType .modal-body {
      padding-top: 0px;
      padding-bottom: 0px;
      padding-left: 12px;
      padding-right: 12px;
  }
  
  #registrationType .popup-columns {
      min-height: 500px;
  }
  
  #registrationType .modal-title span {
      background: url(../images/pattern-for-partners.png) no-repeat;
      display: inline-flex;
      min-width: 43px;
      height: 19px;
      background-size: 85px;
      position: relative;
      top: 2px;
  }
  #registrationType .modal-title {
      color: #273786;
  }
  #registrationType .modal-title {
    color: #273786;
    margin-left: 19px;
    margin-top: 12px;
}
  
  .popup-columns h3 {
      font-size: 25px;
      color: #fff;
  }
  .popup-columns h3 span{
      background: url(../images/pattern-for-partners.png) no-repeat;
      display: inline-flex;
      min-width: 43px;
      height: 19px;
      background-size: 85px;
      position: relative;
      top: 2px;
  }
  
  
  .why-delegate {
      color: #fff;
      margin-top: 30px;
      padding-left: 50px;
  }
  
  .why-delegate h4 {
      font-size: 18px;
      margin-bottom: 19px;
  }
  
  .why-delegate p {
      font-size: 16px;
  }
  
  .why-delegate p span.rupees {
      font-size: 20px;
  }
  
  .btn-reg {
      border: 2px solid #fff !important;
      color: #fff !important;
      border-radius: 30px !important;
      min-width: 160px !important;
	  font-size: 18px;
  }
  .delegate-btn-cntnr{
      color: #fff;
      margin-top: 30px;
      padding-left: 50px;
  }
  
  .popup-columns .col-md-6:last-child h3 span {
      background-position: -43px 0px;
  }
  
  .popup-columns .col-md-6:last-child h3 {
      color: #000;
  }
  
  .popup-columns .col-md-6:last-child .why-delegate {
      color: #000;
      margin-top: 70px;
  }
  
  .popup-columns .col-md-6:last-child .why-delegate p {
      font-size: 17px;
  }
  
  .popup-columns .col-md-6:last-child .delegate-btn-cntnr {
      margin-top: 128px;
  }
  
  .popup-columns .col-md-6:last-child .delegate-btn-cntnr a {
      border-color: black !important;
      color: #000 !important;
  }
  
  .or-cntnr {
      position: absolute;
      width: 68px !important;
      height: 68px ;
      border-radius: 50%;
      background: #e13231;
      color: #fff;
      font-size: 22px;
      display: flex;
      justify-content: center;
      align-items: center;
      left: 33.3%;
      top: 60%;
      margin-left: -34px;
      margin-top: -34px;
  }
  .or-cntnr.another-or {
		left: 66.6%;
	}
	.popup-columns .col-md-4:nth-child(4) h3 span {
		background-position: -43px 0px;
	}	
	.popup-columns .col-md-4:nth-child(4) .delegate-btn-cntnr {
		/*margin-top: 297px;*/

	}
	.popup-columns .col-md-4:nth-child(5) .delegate-btn-cntnr {
		/*margin-top: 297px;*/
	}
	.registration-type-popup .modal-content {
		height: auto !important;
	}

  .btn-reg:hover {
      border-color: #fff;
      box-shadow: inset 0 0 0 2px color(srgb 0.75 0.46 0.95);
      background: none !important;
  }
  
  #registrationType .modal-content .popup-columns .col-md-6:last-child .delegate-btn-cntnr a:hover {
      box-shadow: inset 0 0 0 2px #FFEB3B;
  }
  .rupees.strike {
      text-decoration: line-through;
  }
  
  .innerbanner + .why-delegate {
      background: color(srgb 0.38 0.49 0.55 / 0.38);
      color: #000;
      margin-top: 0px;
      padding-top: 20px;
      padding-bottom: 20px;
  }
  
  .innerbanner + .why-delegate h5 {
		font-size: 21px;
		font-weight: 600;
		margin-bottom: 12px;
  }
  
  .innerbanner + .why-delegate p {
      font-size: 17px;
      margin-bottom: 4px;
      font-weight: 400;
  }
  
  .innerbanner + .why-delegate a {
      text-decoration: none;
      color: #000;
      margin-left: 7px;
      font-size: 14px;
  }
  
  .innerbanner + .why-delegate input{
      position:relative;
      top:1px;
  }
  
  .terms {
      font-size: 16px;
      text-decoration: none;
      color: #000;
      margin-left: 8px;
  }
  
  .innerbanner h2 + p {
      position: relative;
      top: 0px;
      margin-bottom: 0px;
  }
  
  /* select registration type */
  
  
  
  /* inner page banner */
  .innerbanner {
      background: #273786;
      color: #fff;
      min-height: 75px;
      display: flex;
      align-items: center;
  }
  
  .innerbanner h2 {
      font-size: 28px;
      font-weight: 300;
      display: inline-flex;
      margin-left: 1px;
      margin-bottom: 0;
  }
  
  .innerbanner h2 span {
      font-weight: 600;
      margin-left: 6px;
  }
  .innerbanner span.logo-strips {
      background: url(../images/pattern-for-partners.png) no-repeat;
      display: inline-flex;
      min-width: 43px;
      height: 19px;
      background-size: 85px;
      position: relative;
      top: 2px;
  }
  
  /* inner page banner end */
  
  
  
  
  /* form page*/
  
  
  .delegate-form input, .delegate-form select  {
      border: none !important;
      border-bottom: 1px solid #2f3685 !important;
      border-radius: 0 !important;
      padding-left: 0;
  } 

  .phoneinput {
    border: none;
    border-bottom: 1px solid #2f3685;
    border-radius: 0;
    padding-left: 0;
  }
  .phoneinput.special-label input {
    border: none;
    border-bottom: 1px solid #2f3685;
    border-radius: 0;
    padding-left: 0;
  }
  
  .delegate-form label {
      font-size: 13px;
  }
  
  .btn-registration {
      min-width: 170px !important;
      font-size: 19px !important;
      display: inline-flex !important;
      align-items: center !important;
      justify-content: space-between !important;
      border-radius: 23px !important;
      padding-left: 21px !important;
      background: #e13231 !important;
      border: navajowhite !important;
  }
  
  .btn-registration:hover {
      background: #b91716;
  }
  
  .delegate-form .input-group {
      position: relative;
      top: 4px;
  }
  
  
  /* form page end*/
  
  
  .banner + .why-delegate p:last-child {
      margin-bottom: 0px;
  }
  .banner + .why-delegate {
      background: #20234c;
      margin-top: 0;
      padding-top: 40px;
      padding-bottom: 40px;
  }
  
  
  .banner + .why-delegate h2 {
      font-weight: 300;
  }
  
  .banner + .why-delegate h2 span {
      font-weight: 600;
	  /*margin-left: 11px;*/
  }
  .banner + .why-delegate p span.rupees {
      font-size: 20px;
  }
  .banner + .why-delegate p {
      font-size: 17px;
	 margin-bottom: 2px; 
	 font-weight: 300;
  }
  footer .contactnom {
      font-size: 16px;
      text-decoration: none;
      color: #fff;
  }
  
  .footer-cntnt-wrpr h4 + p {
      margin-bottom: 7px;
  }
  
  /* about us page */

.page-aboutus .innerbanner {
    min-height: 135px;
}
.festival-on-beach {
    padding: 90px 15px;
}

.kizhakemuri {
    background: #cccccc;
    padding: 92px 0;
}

.kizhakemuri img {
    height: 110px;
}

.kizhakemuri h2 {
    font-size: 28px;
}

.festival-director {
    padding: 90px 0;
}

.klf-venue {
    background: url(../images/Calicut-KLF-Venue.jpeg) no-repeat;
    background-size: cover;
    min-height: 755px;
    display: flex;
    align-items: center;
}

.klf-venue .card {
    border-radius: 0px;
    border: none;
    background: color(srgb 1 1 1 / 0.70);
    min-height: 460px;
    display: flex;
    justify-content: center;
	padding: 30px;
}
.klf-venue .card img {
    max-width: 170px;
}

.klf-venue .card .col-md-4 {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 0;
}
.festival-on-beach img {
    margin-bottom: 20px;
}

.kizhakemuri {
    background: color(srgb 0.8 0.8 0.8 / 0.4);
}
.navbar-nav .nav-link.active {
    color: #e13231;
}
.footer-menu-wrpr a.active {
    color: #e13231;
}

/* about us page */


/* benefits table */

.benefits-wrpr {
    background: #c4cdd3;
    padding: 0px;
    margin-top: 10px;
    border-radius: 10px;
    overflow: hidden;	
}

.benefits-wrpr .table{
    width:100%;
    table-layout: fixed;
	font-size: 17px;
	margin-bottom:0px;
}
.benefits-wrpr thead {
    background: #7f539e;
    color: #fff;
    border-color: #7e539d;
    font-weight: 600;
	    font-size: 15px;
}
.benefits-wrpr i {
    display: inline-flex;
    width: 25px;
    height: 25px;
    background: #FFEB3B;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    font-size: 16px;
}
.benefits-wrpr i.icon-close {
    background: #e13231;
    color: #fff;
}

.benefits-wrpr tbody tr td:first-child {
    padding-left: 15px;
    font-weight: 400;
	font-size:17px;
}

.delegate-register-wrpr {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 9px;
}

.delegate-register-wrpr a {
    width: 100%;
    background: #d83d36;
    border: none;
    min-height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    letter-spacing: .5px;
    font-size: 20px;
}

.wouldyou {
    margin-bottom: 9px;
}
.benefits-wrpr thead tr td:first-child {
    padding-left: 15px;
}



/* benefits table */
  
 
#registrationType .modal-content .popup-columns .col-md-12:nth-child(1) {
    background: #20234c;
    color: #fff;
}

#registrationType .modal-content .popup-columns .col-md-4 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
#registrationType .modal-content .popup-columns .col-md-4:nth-child(5) h3 {
    color: #000;
}

#registrationType .modal-content .popup-columns .col-md-4:nth-child(5) .why-delegate p {
    color: #000;
}

#registrationType .modal-content .popup-columns .col-md-4:nth-child(5) .delegate-btn-cntnr .btn-reg {
    border-color: #000 !important;
    color: #000 !important;
}


#registrationType .modal-content .popup-columns .col-md-12:nth-child(3) {
    order: 2;
}
.dele-perks ul {
    list-style: none;
    padding-left: 0;
    margin-top: 8px;
}

.dele-perks li:before {
    content: "\ec33";
    font-family: icons-sm;
    color: #f9cd26;
    margin-right: 15px;
    position: relative;
    top: 0px;
}

.dele-perks li {
    display: inline-flex;
	font-size: 18px;
    line-height: 32px;
    margin-right: 13px;
}

.dele-perks {
    margin-top: 14px;
}
 .dele-perks strong {
    font-size: 24px;
    font-weight: 500;
    margin-left: 2px;
	text-transform: uppercase;	
	    color: #f9cd26;
}
 
.benefits-wrpr i.icon-checkmark3 {
    background: none;
    color: #7f539e;
    font-size: 19px;
    position: relative;
    left: -4px;
    top: 4px;
} 
  
 
 
/* Payment Success */

.innerbanner.paymentsuccess {
    min-height: 320px;
}
.innerbanner.paymentsuccess .icon-thumb_up {
    font-size: 48px;
    width: 100px;
    height: 100px;
    display: inline-flex;
    border: 2px solid #fff;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    color: #f9cd26;
    margin-top: 30px;	
}  
 
.thankyou-hding {
    font-size: 54px;
    color: #f9cd26;
    line-height: 61px;
    margin-top: 36px;
}

.payment-done {
    font-size: 20px;
}
.order-id {
    display: flex;
    justify-content: center;
}

.order-id span {
    display: inline-flex;
    
}

.order-date {
    display: flex;
    margin-bottom: 20px;
    justify-content: center;
    margin-top: 6px;
}

.order-date span {
    
    display: inline-flex;
}

.order-id b, .order-date b {
    display: inline-flex;
    margin-left: 11px;
}

.entrypass {
    display: flex;
    justify-content: center;
    font-size: 22px;
    margin-top: 20px;
    padding-bottom: 14px;
    text-transform: uppercase;
    color: #80529f;
    font-weight: 700;
}
.container-fluid.payment-cntnt-wrpr {
    background: #273786;
} 
 .stamp-card {
    width: 425px;
    height: 508px;
    display: inline-block;
    padding: 23px;
    background: white;
    position: relative;
    -webkit-filter: drop-shadow(0px 0px 10px rgba(0,0,0,0.5));
 /*The stamp cutout will be created using crisp radial gradients*/
    background: radial-gradient( transparent 0px, transparent 14px,white 15px,white);
   background-size: 42px 42px;
	/*Offset to move the holes to the edge*/
	background-position: -18px -20px;
}
.stamp-card-body {
    background: #fff;
    height: 100%;
	padding:20px;
}
.stamp-card-wrpr {
    border: 2px solid #353584;
    height: 100%;
    border-radius: 20px;
    text-align: center;	
	max-height: 400px;	
}

.stamp-card-wrpr .order-id b {
    background: #fff;
    position: relative;
    top: -13px;
    padding: 0 20px;
    font-weight: 400;
    letter-spacing: 1px;
    text-transform: uppercase;
}

img.qr-code {
    max-width: 175px;
    margin-top: 20px;
} 
 
.klf-logo {
    text-align: center;
    width: 264px;
    margin: 0 auto;
    background: #fff;
    padding: 0 10px;
    position: relative;
    top: 19px;
}

.klf-logo img {
    max-width: 224px;
}

.reg-message p {
    color: #fff;
    font-size: 22px;
}
 .line {
    display: inline-flex;
    height: 3px;
    width: 50px;
    background: #e13231;
}
 .red {
    color: #e13231;
}
.yellow {
    color: #f3ce35;
}
.green {
    color: #31a452;
}
.blue {
    color: #4aa6df;
}
.orange {
    color: #ec842c;
}
.purple {
    color: #7f539e;
}
.reg-name {
    font-size: 21px;
}

/* Payment Success */
  
  
  
/* Payment failed */

.btn-tryagain:hover {
    border-color: white;
    box-shadow: 0 0 0 2px;
}	
.innerbanner.paymentfailed  .thankyou-hding {
    font-size: 54px;
    color: #f9cd26;
    line-height: 61px;
    margin-top: 36px;
    margin-bottom: 20px;
}

  .btn-tryagain {
    border: 2px solid #fff;
    margin-top: 20px;
    border-radius: 40px;
    min-width: 150px;
    color: #fff;
}
  .innerbanner.paymentfailed .icon-minus1 {
    font-size: 135px;
}

.innerbanner.paymentfailed {
    padding-top: 40px;
    background: #7f539e;
    padding-bottom: 60px;
    min-height: 699px;
}

.failed-card {
    max-width:400px;
    min-height:450px;
    margin:0 auto;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='81' ry='81' stroke='white' stroke-width='5' stroke-dasharray='50%25%2c 13%25' stroke-dashoffset='40' stroke-linecap='butt'/%3e%3c/svg%3e");
border-radius: 81px;
    background-color: transparent;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
}



  
/* Payment failed */
  
  
  
  .why-delegate .section-btn-wrpr {
    justify-content: flex-start;
}

.why-delegate .section-btn-wrpr a:hover {
    color: #fff;
    box-shadow: 0 0 20px #FF5722;
}
 .caption {
    font-size: 18px;
    margin-top: 8px;
    border-bottom: 1px solid #ec842c;
    padding-bottom: 9px;
	background:#fff;
} 
  
  
.photogallery-wrpr  .swiper-slide {
    text-decoration: none;
    color: #000;
} 
  
  
 /* Videos */ 

.video-img {
    height: 189px;
    width: 100%;
    object-fit: cover;
    border-radius: 0px;
}
.video-slide {
    margin-top: 30px;
}

.swiper-container.video-slide {
    margin-left: 50px;
    margin-right: 50px;
    padding-left: 7px;
    padding-right: 7px;
}

.videos-wrpr .swiper-rightarrow {
    position: absolute;
    top: 175px;
    right: 0;
}
.videos-wrpr .swiper-leftarrow {
    top: 175px;
}

.video-title {
    font-size: 18px;
    color: #000;
    margin-top: 17px;
    min-height: 85px;
}

.videos-wrpr  .swiper-slide.venobox {
    text-decoration: none;
}
 
.videos-wrpr h2 {
    text-transform: uppercase;
    font-weight: 600;
}
.news-wrpr .swiper-pagination-wrpr ,
.videos-wrpr .swiper-pagination-wrpr {
    margin-top: 20px;
}
 
 

.videos-wrpr .swiper-slide.venobox:before {
    content: "\e037";
    font-family: icons-sm;
    font-size: 28px;
    position: absolute;
    top: 50%;
    display: inline-flex;
    width: 50px;
    height: 50px;
    background: transparent;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin-top: -73px;
    left: 50%;
    margin-left: -25px;
    border: 2px solid #fff;
    color: #fff;
    scale:1;
    transition: all .2s linear !important;
      -o-transition: all .2s linear !important;
      -moz-transition: all .2s linear !important;
      -webkit-transition: all .2s linear !important;
	  opacity:.5;
}

.videos-wrpr .swiper-slide.venobox:hover:before {
    scale:1.2;
	opacity:1;
    
} 
 
 /* Videos */ 
  
  
  
 /* New about page */ 

.pic-desc {
    font-size: 14px;
}

.klf-overview {
    background:url(../images/klf-overview-bg.png) no-repeat #20234c left bottom;
    min-height:437px;
    color:#fff;
    padding: 80px 0;
}

.overview-circle {
    width: 79px;
    height: 79px;
    background: #f3ce35;
    display: flex;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 33px;
}

.overview-text {
    text-align: center;
    font-size: 25px;
    margin-top: 28px;	
}

.overview-circle.red.two {
    width: 107px;
    background: #e13231;
    height: 107px;
}

.overview-circle.blue.four {
    width: 107px;
    background: #4aa6df;
    height: 107px;
}

.overview-circle.orange.five {
    background: #ec842c;
}

.overview-circle.green.three {
    background: #31a452;
    width: 160px;
    height: 160px;
}
.klf-overview .col {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.klf-photos {
    padding: 90px 0;
}
 .pic2 {
    height: 199px;
    width: 100%;
    object-fit: cover;
}

.pic4 {
    max-height: 428px;
    width: 100%;
    object-fit: cover;
    object-position: 0px -40px;
}

.first-row-pics {
    max-height: 428px;
    overflow: hidden;
}

.pic1 {
    min-height: 570px;
    object-fit: cover;
}

.pic3 {
    height: 100%;
    max-height: 231px;
    width: 100%;
    object-fit: cover;
    object-position: center;
}
.third-row-pics {
    background: #20234c;
    color: #fff;
    padding: 27px;
}

.third-row-pics .logo-strips {
    background: url(../images/pattern-for-partners.png) no-repeat;
    display: inline-flex;
    min-width: 50px;
    height: 31px;
    background-size: 100px;
    position: relative;
    top: 2px;
}
.third-row-pics .col-md-2 {
    display: flex;
    align-items: center;
    justify-content: center;
}
.third-row-pics p {
    margin-bottom: 0px;
}

.dc-cklf {
    padding: 90px 0;
    position: relative;
}

.dc-cklf:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 50%;
    background: #f3f3f4;
    z-index: -1;
}
.content-wrpr {
    padding: 10px 60px 0px 0px;
}

.dc-cklf .col-md-6:nth-child(2) .content-wrpr {
    padding: 10px 60px 0px 100px;
}

.content-wrpr h2 {
    position: relative;
}

.content-wrpr h2:before {
    content: "";
    background: url(../images/pattern-for-partners.png) no-repeat;
    display: inline-flex;
    min-width: 50px;
    height: 31px;
    background-size: 100px;
    position: relative;
    top: 8px;
    position: absolute;
    left: -66px;
}
.klf-drp{
	width:300px;
}
 
 /* New about page */ 
 
 
 /* popup changes */

	#registrationType .modal-content .popup-columns .col-md-4:nth-child(4) h3 {
		color: #000;
	}

	#registrationType .modal-content .popup-columns .col-md-4:nth-child(4) .why-delegate p {
		color:#000;
	}

	#registrationType .modal-content .popup-columns .col-md-4:nth-child(4) .delegate-btn-cntnr .btn-reg{
		border-color: #000 !important;
		color: #000 !important;
	}
  #registrationType .modal-content:before {
    content: "";
    position: absolute;
    display: block;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border: 11px solid color(srgb 0 0 0 / 0.55);
    z-index: 1;
}
  .btn-close {
    position: relative;
    z-index: 10;
}

.delegate-btn-cntnr {
    position: relative;
    z-index: 10;
}
.why-delegate + .container .klf-drp {
    width: 180px;
    height: 72px;
}

.innerbanner.registration + .why-delegate + .container {
    max-width: 99%;
}
 
  
 /* popup changes */
 
 
 .footer-copy .col-md-12 .archives {
    position: absolute;
    left: 11px;
    background: #2f3685;
    color: #fff;
    padding: 0 18px;
    text-decoration: none;
}

.registration-page + #registrationType .modal-content:before {content: none;}


.registration-page + #registrationType .modal-content {
    margin-top:80px;
    margin-bottom:80px;
    
}
.registration-page + #registrationType .modal-content .popup-columns .col-md-4:nth-child(2) {
    padding-top: 40px !important;
    padding-bottom: 60px !important;
}

.registration-page + #registrationType .modal-content .popup-columns .col-md-4:nth-child(4) {
    padding-top: 40px !important;
    padding-bottom: 60px !important;
}

.registration-page + #registrationType .modal-content .popup-columns .col-md-4:nth-child(5) {
    padding-top: 40px !important;
    padding-bottom: 60px !important;
}

.registration-page + #registrationType .or-cntnr {

    display:none; 
}

.why-delegate {
    position: relative;
}

.why-delegate.wdhome:before {
    content: "";
    position: absolute;
    display: block;
    left: 0;
    top: 0;
    bottom: 0;
    width: 50%;
    background: color(srgb 0.19 0.64 0.32 / 0.82);
}
 
.regfree {
    position: relative;
    z-index: 1;
}

.footer-copy .col-md-12 span.copy {
    position: absolute;
    left: 110px;
} 

.news-wrpr.news-listing-section .news-card {
    margin-bottom: 30px;
}
.newshding-para {
    min-height: 248px;
}

.news-slide.news-at-sidebar img {
    height: 98px;
    object-fit: cover;
}
.news-slide.news-at-sidebar .newshding-para {
    min-height: unset;
}
 .btn-dark.btn-readmore {
    background: transparent;
    color: #000;
}
 .speakers-wrpr img {
    max-width: 170px;
    width: 170px;
    height: 170px;
    border-radius: 50%;
    object-fit: cover;
}
.speakers-wrpr a {
    color: #000;
    text-decoration: none;
}

span.rupees.yellow.font-weight-bold {
    font-weight: 700;
}
span.rupees.yellow {
    font-weight: 700;
}


/* new changes 5 11 2024 */


.videos-wrpr h2 a ,
.news-wrpr h2 a ,
.photogallery-wrpr h2 a ,
.testimonials-wrpr h2 a {
    color: #000;
    text-decoration: none;
}

.videos-wrpr h2 a:hover ,
.news-wrpr h2 a:hover ,
.photogallery-wrpr h2 a:hover ,
.testimonials-wrpr h2 a:hover {
    color: #e13231;
}

.videos-wrpr .btn-yellow:hover ,
.news-wrpr .btn-yellow:hover ,
.photogallery-wrpr .btn-yellow:hover ,
.testimonials-wrpr .btn-yellow:hover {
    color: #000;
    box-shadow: 0 0 0 3px #ec842c;
}

.delegate-form textarea{
	    border: none !important;
    border-bottom: 1px solid #2f3685 !important;
    border-radius: 0 !important;
    padding-left: 0;
	margin-top:5px;
}


.filterlabel-wrpr {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 13px;
    font-weight: 600;
}

.filterlabel-wrpr a {
    text-decoration: none;
    color: #000;
    cursor: pointer;
    padding: 3px;
    border-radius: 3px;	
}
.filterlabel-wrpr a:hover {
    border: 1px solid #dadada;
}

.filterlabel-wrpr .filterlabel {
    font-size: 16px;
}

.time-item {
    border-bottom: 1px solid #dadada;
    padding: 7px;
}

.time-item:first-child {
    border-top: 1px solid #dadada;
}

.eventcategory-item:first-child {
    border-top: 1px solid #dadada;
}

.eventcategory-item ,
.eventspeakers-item {
    border-bottom: 1px solid #dadada;
    padding: 7px;
	display:flex;
	text-decoration:none;
	color:#000;
}
.filters-cntnr {
    max-height: 700px;
    overflow: hidden;
    overflow-y: auto;
}

.sessions-tabs {
    position: relative;
    top: -40px;
}

.sessions-tabs .nav.nav-tabs {
    border-bottom: none;
	position: relative;
}

.sessions-tabs .nav.nav-tabs a.nav-link.active {
    border: none;
    color: #e13231;
    border-radius: 5px;	
}
.sessions-tabs .nav.nav-tabs:before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: -4px;
    height: 11px;
    background: #ffffff;
    z-index: 100;
}
.sessions-tabs .nav.nav-tabs a.nav-link {
        border: none;
    color: #2f3685;
    font-size: 18px;
    padding-left: 5px;
    padding-right: 5px;
    width: 95px;
    height: 47px;
    background: #fff !important;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    margin: 0 4px;
    font-weight: 600;
    box-shadow: 0 0 0 3px;
}
.session-filter-wrpr h5 {
    border-bottom: 1px solid #f9cd26;
    margin-bottom: 20px;
    padding-bottom: 11px;
}

.filterlabel-wrpr.p-relative {
    position: relative;
}
.yellow .date-and-time {
    color: #000;
}

.icon-filter_list,
.icon-filter_list:active,
.icon-filter_list:focus {
   position: absolute;
    font-size: 30px;
    color: #fff;
    right: 17px;
    bottom: 1px;
    text-decoration: none;
    border: 1px solid color(srgb 1 1 1 / 0.32);
    border-radius: 4px;
    padding: 3px;
}
.icon-filter_list.active{
	color:#fff;
}
.icon-filter_list.active:before {
    content: "\e5cd";
}
.search-icon-svg {
    position: absolute;
    width: 14px;
    left: 9px;
    top: 10px;
    z-index: 100;
}
.collapse .search-wrpr input {
    border-radius: 0px;
    background: color(srgb 0.75 0.75 0.75 / 0.21);
}
.search-cntnr input.form-control {
    border-radius: 0px;
    padding: 11px;
    padding-left: 35px;
}
.search-wrpr{
	position:relative;
	margin-top: 23px;
}
.btn-search-events {
    padding: 3px !important;
    background: #7e529e !important;
    color: #fff !important;
    border-color: #7e529e !important;
    min-width: 130px !important;
    justify-content: center !important;
    position: relative !important;
    left: -8px !important;
}
/* new changes 5 11 2024 */

 
/* new changes 6 11 2024 */

.videos-wrpr .venobox {
	position:relative;
}
.videos-wrpr .venobox:hover:before {
    scale: 1.2;
    opacity: 1;
}

.videos-wrpr .venobox:before {
    content: "\e037";
    font-family: icons-sm;
    font-size: 28px;
    position: absolute;
    top: 50%;
    display: inline-flex;
    width: 50px;
    height: 50px;
    background: transparent;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin-top: -73px;
    left: 50%;
    margin-left: -25px;
    border: 2px solid #fff;
    color: #fff;
    scale: 1;
    transition: all .2s linear !important;
    -o-transition: all .2s linear !important;
    -moz-transition: all .2s linear !important;
    -webkit-transition: all .2s linear !important;
    opacity: .5;
}

 
.swiper-button-prev.banner-prev {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E") !important;
    background-size:23px 44px;
    background-repeat: no-repeat !important;
    color: transparent !important;
  }
  
  .swiper-button-next.banner-next {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E") !important;
    background-size:23px 44px;
    background-repeat: no-repeat !important;
    color: transparent !important;
  }
 
 .photogallery-cntnr img {
    max-height: 200px;
    min-height: 200px;
    object-fit: cover;
}
 
/* new changes 6 11 2024 */

 
/* new changes 8 11 2024 */


.whyDelegate-new .col-md-6 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.whyDelegate-new .col-md-6 h2 {
    font-size: 56px;
    text-align: center;
	font-weight: 400;
}

.whyDelegate-new .col-md-6 h2 em {
    display: block;
    font-size: 33px;
    font-style: normal;
}

.whyDelegate-new .col-md-6 h3 {
    font-size: 27px;
    margin-top: 30px;
}

.whyDelegate-new .btn-dark.btn-red {
    width: 209px;
    height: 54px;
    font-size: 27px;
    line-height: 53px;
}
.readmore {
    color: #fff;
    text-decoration: none;
    font-size: 17px;
    margin-top: 21px;
    display: inline-flex;
    align-items: center;
}

.readmore i {
    margin-left: 1px;
}
.why-delegate.whyDelegate-new {
	padding-top:0px;
	padding-bottom:0px;
    padding-left: 0px;
    padding-right: 0;
}

.why-delegate.whyDelegate-new .col-md-6 {
    padding-top: 70px;
    padding-bottom: 70px;
	background-image: url('../images/why-delegate-bg1.png'), url('../images/why-delegate-bg2.png');
	background-position: right bottom, left bottom;
	background-repeat: no-repeat, no-repeat;
}

.why-delegate.whyDelegate-new .col-md-6:nth-child(2) {
	background-image: url('../images/why-delegate-bg3.png'), url('../images/why-delegate-bg4.png');
	background-position: right bottom, left bottom;
	background-repeat: no-repeat, no-repeat;
}

.why-delegate.whyDelegate-new span.logo-strips {
	
    background: url(../images/pattern-for-partners.png) no-repeat;
    display: inline-flex;
    min-width: 63px;
    height: 39px;
    background-size: 124px;
    position: relative;
    top: 2px;
}

.spacetoConnect .modal-title span ,
.WhyshouldYoube .modal-title span {
    background: url(../images/pattern-for-partners.png) no-repeat;
    display: inline-flex;
    min-width: 43px;
    height: 19px;
    background-size: 85px;
    position: relative;
    top: 2px;
}
.spacetoConnect  .modal-content ,
.WhyshouldYoube  .modal-content {
    border: none;
    border-radius: 0px;
}
.spacetoConnect .modal-body ,
.WhyshouldYoube .modal-body {
    background: #3b8b52 url(../images/why-delegate-bg1.png) no-repeat right bottom;
    color: #fff;
    padding: 29px 40px;
}
.WhyshouldYoube .modal-body {
	background: #22234b url(../images/why-delegate-bg3.png) no-repeat right bottom;
}
.readmore:hover {
    color: #fff;
    border-bottom: 1px solid #fff;
}
 .whyDelegate-new .btn-dark.btn-red:focus {
    color: #fff;
}
/* new changes 8 11 2024 end */


/* new changes 18 11 2024 start */

.testimonial-para-wrpr {
    max-height: 120px;
	min-height: 120px;
    overflow: hidden;
    overflow-y: auto;
	margin-bottom:20px;
}

.testimonial-para-wrpr::-webkit-scrollbar {
    width: 5px;
}
 
.testimonial-para-wrpr::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
}
 
.testimonial-para-wrpr::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}
 
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #fff; 
}
/* new changes 18 11 2024 end */


/* new changes 20 11 2024 start */

.partner-section-title-wrpr h3 span {
    background: #ffcb08;
    padding: 4px 20px;
    display: inline-flex;
    border-radius: 40px;
    font-size: 18px;
    color: #000;
    min-width: 150px;
    justify-content: center;
}

.partner-section-title-wrpr h3 {
    margin-top: 30px;
}
.partner-section-title-wrpr.gold {
    margin-top: 30px;
}
 
.diamond-logos .col-md-4 ,
.diamond-logos .col-md-3 {
    align-items: center;
    justify-content: center;
    display: flex;
}

.diamond-logos .col-md-4 img ,
.diamond-logos .col-md-3 img {
    max-width: 170px;
}
.gold-logos img ,
.silver-logos img ,
.bronze-logos img {
    max-width: 60px;
    max-height: 60px;
}
 .diamond-logos .col-md-3 ,
 .gold-logos .col-md-3 ,
.silver-logos .col-md-3 ,
.bronze-logos .col-md-3 {
    padding-top:50px;
	padding-bottom:50px;
	
}
.partner-section-title-wrpr hr {
    position: relative;
    top: -31px;
    color: color(srgb 0.62 0.62 0.62 / 0.68);
}

.partner-section-title-wrpr h3 span {
    position: relative;
    z-index: 1;
}


/* new changes 20 11 2024 end */


/* new changes 21 11 2024 start */

.testimonials-listing .testim-user img {
    min-height: 237px;
}
.testimonials-listing .col-md-3 {
    margin-bottom: 24px;
}
.testimonials-listing  .card.testimonial-card .testim-cntnt {
    padding: 20px;
}



.whyDelegate-new-2 {
    padding-top: 60px;
    padding-bottom: 60px;
}

.whyDelegate-new-2 img {
    max-width: 330px;
    height:auto;
}
 
.whyDelegate-new-2 .col-md-4 {
    position: relative;
}

.whyDelegate-new-2 .col-md-4 .readmore-1 {
    position: absolute;
    left: 43px;
    bottom: 91px;
    width: 93px;
    height: 23px;
    color: #17475a;
    text-decoration: none;
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 15px;
}

.whyDelegate-new-2 .col-md-4 .register {
    position: absolute;
    left: 237px;
    bottom: 39px;
    width: 107px;
    height: 100px;
	background: url(../images/reg-btn-one.png) no-repeat;
    background-size: cover;
	transition: all .2s linear !important;
      -o-transition: all .2s linear !important;
      -moz-transition: all .2s linear !important;
      -webkit-transition: all .2s linear !important;
	
}

.whyDelegate-new-2 .col-md-4.whyshould .readmore-1 {
    height: 10px;
    left: 178px;
    bottom: 95px;
}


.whyDelegate-new-2 .col-md-4.whyshould .register {
    right: unset;
    left: 50px;
    bottom: -4px;
    width: 111px;
	background: url(../images/reg-btn-two.png) no-repeat;
    background-size: cover;
} 
.whyDelegate-new-2 .col-md-4.whyshould .readmore-1 {
    bottom: 85px;
    color: #fff;
}

.whyDelegate-new-2 .col-md-4.beStudent .register {
    right: unset;
    left: 67px;
    bottom: 10px;
} 

.register:hover {
    scale: 1.1;
}
/* new changes 21 11 2024 end */


/* new changes 22 11 2024 start */

.whyDelegate-new-2 .whyshould img {
    max-width: 295px;
}

.whyDelegate-new-2 .beStudent img {
    max-width: 310px;
}
 
 
/* new changes 22 11 2024 end */


/* new changes 25 11 2024 start */


.speakers-prev,
.speakers-next{
	position:relative !important;
}
 
/* new changes 25 11 2024 end */ 
  
  
/* new changes 27 11 2024 start */ 

.groupinfo-wrpr .card {
    border: none;
    background: #eaebf3;
}
.group-list-wrpr ol {
    list-style: none;
    padding-left: 0;
}

.group-list-wrpr ol li {
    background: #fff;
    border-radius: 6px;
    line-height: 46px;
    color: #2f3685;
    font-size: 19px;
    padding-left: 9px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 9px;
    margin-bottom: 9px;
}
.group-list-wrpr ol li:last-child{
	margin-bottom: 0px;
}

.group-list-wrpr ol li a {
    color: #353584;
    text-decoration: none;
    background: #f9cd26;
    display: inline-flex;
	min-width:28px;
    width: 28px;
    height: 28px;
    justify-content: center;
    border-radius: 50%;
    line-height: 27px;
}

.group-list-wrpr ol {
  list-style: none;
  counter-reset: my-awesome-counter;
  margin-bottom:0px;
}
.group-list-wrpr ol li {
  counter-increment: my-awesome-counter;
}
.group-list-wrpr ol li::before {
  content: counter(my-awesome-counter);
  color: #353584;
  
  background:#c1c3db;
  display:inline-flex;
  min-width:28px;
  width:28px;
    height:28px;
  border-radius:50%;
  justify-content:center;
  align-items:center;
}
.group-list-wrpr ol li span {
    justify-content: space-between;
    align-items: center;
    width: 100%;
    display: inline-flex;
    padding-left: 10px;
}
.group-list-wrpr ol li.group-admin {
    background: #273786;
    color: #fff;
}
.btn-addmember {
    background: #273786 !important;
}


/* new changes 27 11 2024 end */ 



/* new changes 28 11 2024 start */ 

.countryselect-wrpr:before {
    content: "Country:";
    display: inline-flex;
    margin-right: 9px;
    align-items: center;
}

.countryselect-wrpr {
    display: inline-flex;
    width: 100%;
    border-bottom: 1px solid #2f3685;
}

.countryselect-wrpr select {
    position: relative;
    top: 1px;
}

.why-delegate.bottompos {
    color: #000;
    margin-top: 30px;
    padding: 20px;
    background: #c4cdd3;
    z-index: 1;
    position: relative;
}

.email-wrpr {
    padding-top: 4px;
}

.required ::placeholder {
  color: red;
  opacity: 1; /* Firefox */
}

.required ::-ms-input-placeholder { /* Edge 12-18 */
  color: red;
}

.btn-groupregister {
    background: #e13231;
    border-color: #e13231;
}

.btn-groupregister:hover {
    background: red;
}

.sessions-tabs .nav li:nth-child(2) a {
    background: #e13230 !important;
}
.sessions-tabs .nav li:nth-child(3) a {
    background: #31a452 !important;
}
.sessions-tabs .nav li:nth-child(4) a {
    background: #4aa6df !important;
}
.sessions-tabs .nav li:nth-child(4) a {
    background: #ec852e !important;
}
.sessions-tabs .nav li:nth-child(5) a {
    background: #7f539e !important;
}

.sessions-tabs .col-md-6 {
    display: flex;
    justify-content: center;
    align-items: center;
}
.dropdown-wrpr {
    display: inline-flex;
}
.filter-wrpr select {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
	background-repeat:no-repeat;
    background-position: right .75rem center;
    background-size: 16px 12px;
	background-color:#f2f1f1;
}

.select2-container--default .select2-selection--single {
    background-color: #f2f1f1;
    border: 1px solid #cfd4da;
    border-radius: 4px;
    height: 31px;
}

.search-wrpr input {
    height: 32px;
    padding-left: 34px;
}


/* new changes 28 11 2024 end */ 



/* new changes 29 11 2024 start */ 

.symbol-venue-fav-wrpr {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.symbol-venue {
    display: flex;
}

.symbol-venue img {
    width: 25px;
    height: 16px;
}

.venue {
    display: flex;
    font-size: 13.5px;
    padding-left: 10px;
}

.fav-wrpr {
    height: 21px;
}

.fav-wrpr a {
    text-decoration: none;
    color: #bababa;
	font-size: 23px;
}

.venue-mal {
    margin-left: 1px;
}

.venue-mal:before {
    content: "/";
    display: inline-flex;
    padding-right: 3px;
}
.session-type {
    display: flex;
}
.card.session-card .card-body {
    padding-bottom: 7px;
    min-height: 260px;
}
.date-and-time .time, .date-and-time .date {
    display: flex;
    align-items: center;
}

.date-and-time .time i {
    margin-right: 4px;
}

.date-and-time .date i {
    margin-right: 6px;
    font-size: 11px;
}

.not-btn {
    background: none;
    border: none;
}

.not-btn.phoneonly {
    color: #000;
}

.btn-groupregister span {
    color: #f9cd26;
    margin-left: 5px;
    font-size: 17px;
    border-bottom: 1px solid;
}

.btn-groupregister.not-btn:hover,
.btn-groupregister.not-btn:active,
.btn-groupregister.not-btn:focus-visible,
.btn-groupregister.not-btn:focus {
    background: transparent !important;
	border:none  !important;
	box-shadow:none  !important;
}

.btn-groupregister.phoneonly span {
    color: #ec842c;
}

.btn-groupregister.phoneonly span + i {
    color: #ec842c;
}
.btn-groupregister span + i {
    color: #f9cd26;
}
.select2-container--default .select2-search--dropdown .select2-search__field:focus-visible {
    outline: none;
    border-color: #FF9800;
}
.addfav.active {
    color: #ec842c;
}
.icon-filter_list:hover {
    color: #fff;
}
.multicolorline {
    width: 100%;
    margin-top: 30px;
}

/* new changes 29 11 2024 end */ 


/* new changes 2 12 2024 start */ 
	.reg-popup-wrpr {
		display: inline-flex;
	}

	.reg-popup-wrpr a {
		line-height: 30px;
	}


/* new changes 2 12 2024 end */ 



/* new changes 5 12 2024 start */ 


.speakers-wrpr.speakers-list img {
    /*padding: 10px;
    border: 2px dashed #f9cd26;*/
    margin: 0 auto;
    margin-top: 15px;
}

.speakers-wrpr.speakers-list .card {
    border: none;
    box-shadow: 0 0 40px color(srgb 0 0 0 / 0.12);
    padding: 30px;
	position:relative;
	transition: all .2s linear !important;
      -o-transition: all .2s linear !important;
      -moz-transition: all .2s linear !important;
      -webkit-transition: all .2s linear !important;
	      min-height: 478px;
}

.speakers-wrpr.speakers-list .btn-dark.btn-readmore {
    border-color: #e13231;
    min-width: 99px;
	transition: all .2s linear !important;
      -o-transition: all .2s linear !important;
      -moz-transition: all .2s linear !important;
      -webkit-transition: all .2s linear !important;
}


.speakers-wrpr.speakers-list .btn-dark.btn-readmore:hover {
    border-color: gray;
}
.speakers-wrpr.speakers-list .card:hover {
    scale: 1.02;
	
}


.speakers-wrpr.speakers-list .card:before {
    content: "";
    display: block;
    width: 4px;
    height: 100px;
    background: #f9cd29;
    position: absolute;
    left: -2px;
    top: 50%;
    margin-top: -50px;
}
.speakers-wrpr.speakers-list .card:after {
    content: "";
    display: block;
    width: 4px;
    height: 100px;
    background: #f9cd29;
    position: absolute;
    right: -2px;
    top: 50%;
    margin-top: -50px;
}
.speaker-description {
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
.speaker-description p, .speaker-description p em{
    font-size: 16px;
    letter-spacing: normal;
    font-weight: 400;
	font-style:nomal;
}

.speakers-wrpr.speakers-list .btn-dark.btn-readmore {
    border-color: #e13231;
    min-width: 99px;
    display: inline-flex;
    max-width: 100px;
    justify-content: center;
    margin: 0 auto;
}

 .about-speaker .modal-content {
    border: none;
    border-radius: 0px;
	height: auto !important;
}

.about-speaker .modal-body {
    background: #22234b url(../images/why-delegate-bg3.png) no-repeat right bottom;
	padding: 29px 40px;
}
.about-speaker  .modal-title span {
    background: url(../images/pattern-for-partners.png) no-repeat;
    display: inline-flex;
    min-width: 43px;
    height: 19px;
    background-size: 85px;
    position: relative;
    top: 2px;
}
.about-speaker p {
    color: #fff;
}

.about-speaker p em, .about-speaker p span {
    color: #fff !important;
}

.about-speaker  img {
    padding: 10px;
    border: 2px dashed #f9cd26;
    margin: 0 auto;
    margin-top: 15px;
}

.about-speaker  img {
    max-width: 170px;
    width: 170px;
    height: 170px;
    border-radius: 50%;
    object-fit: cover;
}

.event_time h2 {
    color: #f9cd26;
    font-size: 24px;
}

.event_time .time {
    font-weight: 400;
	color:#fff;
}

.speakers-wrpr.speaker-details-wrpr .card {
    border: none;
    box-shadow: 0 0 80px color(srgb 0 0 0 / 0.08);
    padding: 18px;
    display: flex;
    flex-direction: row;
    border-radius: 0px;	
	background: url(../images/multicolor-hline.jpg) no-repeat;
}

.speaker-pic-wrpr {
    min-width: 220px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 27px;
}
.speaker-pic-wrpr img {
    /*padding: 10px;
    border: 3px dashed #f9cd26;*/
}

.small-card {
    background: url(../images/pattern-for-counter-section.png) no-repeat #fff 17px 18px;
    padding: 16px;
    background-size: 42px;
    padding-left: 71px;
    box-shadow: 0 0 20px color(srgb 0 0 0 / 0.09);
    position: relative;
    left: -39px;
    border-left: 3px solid #f9cd27;
}

.small-card p {
    margin-bottom: 0px;
    font-size: 13px;
    font-weight: 500;
}

.small-card h5 {
    margin-bottom: 0px;
}

.small-card + .small-card {
    margin-top: 10px;
    border-color: #e13231;
}

.small-card + .small-card + .small-card {
    border-color: #31a452;
}

.small-card + .small-card + .small-card  + .small-card {
    border-color: #4aa6df;
}

.small-card + .small-card + .small-card  + .small-card  + .small-card {
    border-color: #ec842c;
}

.small-card + .small-card + .small-card  + .small-card  + .small-card   + .small-card {
    border-color: #7f539e;
} 

.small-card-wrpr {
    display: flex;
    flex-wrap: wrap;
}

.small-card-wrpr .small-card {
    margin-top: 0px;
    margin-bottom: 11px;
    margin-right: 11px;
    left: unset;
}
 
/* new changes 5 12 2024 end */ 


/* new changes 6 12 2024 start */ 

.session-members.speakers:before {
    content: "Speakers";
}

.session-members.modarators:before {
    content: "Moderator";
}
.sponsor-logo-ruppe-icon > div:before {
    /*content: "Supported by: ";
	display:block;*/
}
.session-members.storyteller:before {
    content: "Story Teller";
}
.session-members.modarators ul li::marker {
    color: #e13231;
}
.session-type.category-wrpr {
    justify-content: space-between;
    align-items: flex-end;
}

.session-category {
    font-size: 12px;
    font-weight: 500;
}

.session-category:before {
    content: "Category:";
    display: block;
    text-align: left;
    font-weight: 300;
}
/* new changes 6 12 2024 end */ 


/* new changes 9 12 2024 start */ 

.swiper-container.speakers-slide {
    padding: 20px;
}

.speakers-wrpr.speakers-list .card.swiper-slide {
    box-shadow: 0 0 5px color(srgb 0 0 0 / 0.1);
}

/* new changes 9 12 2024 end */ 


/* new changes 10 12 2024 start */ 

.sessions-tabs .nav.nav-tabs .nav-item:nth-child(1) a.nav-link.active {
    color: #f9cd26;
}
.sessions-tabs .nav.nav-tabs .nav-item:nth-child(2) a.nav-link.active {
    color: #e13231;
}
.sessions-tabs .nav.nav-tabs .nav-item:nth-child(3) a.nav-link.active {
    color: green;
}
.sessions-tabs .nav.nav-tabs .nav-item:nth-child(4) a.nav-link.active {
     color: #4aa6df;
}
.sessions-tabs .nav.nav-tabs .nav-item:nth-child(5) a.nav-link.active {
    color: #ec842c;
}
.session-members li a {
    color: #000;
    font-weight: 400;
}
/* new changes 10 12 2024 end */ 


/* new changes 17 12 2024 start */ 

.speakers-wrpr.speakers-list .card.red:before,
.speakers-wrpr.speakers-list .card.red:after{
    background: #e13231;
}
.speakers-wrpr.speakers-list .card.green:before,
.speakers-wrpr.speakers-list .card.green:after{
    background: #31a452;
}
.speakers-wrpr.speakers-list .card.blue:before,
.speakers-wrpr.speakers-list .card.blue:after{
    background: #4aa6df;
}
.speakers-wrpr.speakers-list .card.orange:before,
.speakers-wrpr.speakers-list .card.orange:after{
    background: #ec842c;
}
.speakers-wrpr.speakers-list .card.purple:before,
.speakers-wrpr.speakers-list .card.purple:after{
    background: #7f539e;
}
.speakers-wrpr.speakers-list .card.yellow:before,
.speakers-wrpr.speakers-list .card.yellow:after{
    background: #f9ce29;
}

.speakers-wrpr.speakers-list .card.red:before,
.speakers-wrpr.speakers-list .card.red:after{
    background: #e13231;
}




.speaker-description, .speaker-name {
    color:#000 !important;
}

/* new changes 17 12 2024 end */ 



/* new changes 18 12 2024 start */ 

.speakers-wrpr.speaker-details-wrpr  .card.session-card {
    flex-direction: column;
    background: none;
    padding: 0px;
    box-shadow: none;
    border: 1px solid #1d298c;
    border-radius: 13px;
    overflow: hidden;
}
.speakers-wrpr.speaker-details-wrpr  .card.session-card .scard-header{
	background: #eae9e9;
}
    
.speakers-wrpr.speaker-details-wrpr .card.session-card .card-body {
    background: #f2f1f1;
	width:100%;
}

.speakers-wrpr.speaker-details-wrpr .card.session-card:hover {
    box-shadow: 0 0 32px color(srgb 0 0 0 / 0.13);
}

  
/* new changes 18 12 2024 end */ 


/* new changes 26 12 2024 start */ 

.ipad .sessions-tabs .nav.nav-tabs a.nav-link {
    position: relative;
    width: 59px;
    top: -82px;
    font-size: 14px;
}

.googlemap-container img {
    width: 100%;
    height: auto;
}

/* new changes 26 12 2024 end */ 


/* new changes 6 1 2025 start */ 

.festival-partners-wrpr img {
    max-width: 90px;
	max-height:90px;
}

.festival-partners-wrpr .col-md-12 .col-md-2, .festival-partners-wrpr .col-md-12 .col-md-3{
	min-height:120px;
	margin:0px;
}

.festival-partners-wrpr .card {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    min-height: 148px;
    border-radius: 3px;
	position:relative;
	margin-bottom:20px;
}

.festival-partners-wrpr .col-md-12 .col-md-2, .festival-partners-wrpr .col-md-12 .col-md-3{
	border:none;
}

.partner-type {
    font-size: 13px;
    text-transform: capitalize;
    padding-top: 17px;
	position:absolute;
	bottom:10px;
}


#install-app .modal-content {
	max-width: 467px;
    border: none;
    box-shadow: 0px 20px 50px color(srgb 0 0 0 / 0.2);
    background: #2f3685;
    color: #fff;
    padding-bottom: 16px;
}

#install-app .modal-header {
    padding: 5px 10px;
    border: none;
    background: #fff;
    color:#000;
    
}

#install-app .modal-header h4 {
    font-size:19px;
    margin-bottom:0px;
}

#install-app .modal-content img {
    border-radius: 8px;
}

.fav-wrpr .icon-location {
    font-size: 18px;
    position: relative;
    top: -3px;
    color: #9C27B0;
}

.date-time {
    display: flex;
	margin-bottom:7px;
}

.date-time .date {
	color:#000;
    font-size: 13px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1px 4px;
    border-radius: 5px;
    margin-right: 5px;
}
.date-time .date.yellow {
	background: #f9cd26;
}
.date-time .date.red {
	background: #eb2028;
	color:#fff;
}

.date-time .date.green {
	background: #00a450;
	color:#fff;
}
.date-time .date.blue {
	background: #2ca9e1;
	color:#fff;
}

.date-time .time {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    position: relative;
}

.date-time .time i {
    margin-right: 3px;
    font-size: 14px;
}
.date-time .time span {
    position: relative;
    top: 2px;
}
.venue .icon-location {
    color: #da4a38;
    text-decoration: none;
    margin-left: 7px;
    position: relative;
    top: 0px;
    font-size: 17px;
}
.icon-location-custom img {
    width: 16px;
    height: auto;
}

.icon-location-custom {
    margin-left: 8px;
}


/* new changes 6 1 2025 end */ 

/* new changes 8 1 2025 start */ 

.phone-and-email {
    display: flex;
    flex-direction: column;
}

.phone-and-email a {
    color: #fff !important;
    text-decoration: none;
    margin-bottom: 6px;
}

.phone-and-email a i {
    display: inline-flex;
    background: #f9cd26;
    padding: 5px;
    font-size: 13px;
    border-radius: 50%;
    color: #fff;
    margin-right: 5px;
}

.phone-and-email a + a i {
    background: #53d75f;
    color: #fff;
}
.phone-group a + a {
	margin-left:5px;
}
.phone-group + a i {
    background: #e13231;
}
.phone-group {
	margin-bottom:5px;
}
.dropdown.speaker-filter button {
    border-color: #e13231;
    padding: 8px;
    background: #fff !important;
}

.dropdown.speaker-filter {
    position: relative;
}

.dropdown.speaker-filter:before {
    content: "\e7ff";
    position: absolute;
    right: 10px;
    top: 0px;
    font-family: 'icons-sm';
    font-size: 27px;
    color: #7f539e;
}

.sponsoredby-title {
    font-size: 13px;
    padding-left: 10px;
    margin-bottom: 2px;
}
.scard-heading {
    min-height: 86px;
}
.session-members.storyTeller ul {
    margin-bottom: 0px !important;
}
/* new changes 8 1 2025 end */ 


/* new changes 11 1 2025 start */ 

.container-fluid.download-app {
    background: url(../images/pattern-4.png) #0f1b24;
    min-height: 700px;
    padding-top: 51px;
    padding-bottom: 50px;
}
.store-btn {
    border-radius: 9px;
}

.download-app .qr-code {
    max-width: 147px;
    background: #fff;
    padding: 5px;
}

.sessions-tabs .nav.nav-tabs .nav-item:nth-child(1) a.nav-link {
    background: #f3ce35 !important;
    color: #000 !important;
    box-shadow:0px 0 3px transparent ;
}

.sessions-tabs .nav.nav-tabs .nav-item:nth-child(2) a.nav-link {
    background: #e1312f !important;
    color: #fff !important;
    box-shadow:0px 0 3px transparent ;
}

.sessions-tabs .nav.nav-tabs .nav-item:nth-child(3) a.nav-link {
    background: #2ca352 !important;
    color: #fff !important;
    box-shadow:0px 0 3px transparent ;
}

.sessions-tabs .nav.nav-tabs .nav-item:nth-child(4) a.nav-link {
    background: #4ba7e0 !important;
    color: #fff !important;
    box-shadow:0px 0 3px transparent ;
}
.sessions-tabs .nav.nav-tabs .nav-item:nth-child(5) a.nav-link {
    background: #ec842c !important;
    color: #fff !important;
    box-shadow:0px 0 3px transparent ;
}


.sessions-tabs .nav.nav-tabs .nav-item:nth-child(1) a.nav-link.active,
.sessions-tabs .nav.nav-tabs .nav-item:nth-child(2) a.nav-link.active ,
.sessions-tabs .nav.nav-tabs .nav-item:nth-child(3) a.nav-link.active ,
.sessions-tabs .nav.nav-tabs .nav-item:nth-child(4) a.nav-link.active ,
.sessions-tabs .nav.nav-tabs .nav-item:nth-child(5) a.nav-link.active 
{
    box-shadow:0px 0 0 3px #ffffff ;
}


.fav-modal{
    max-width: 400px!important;
}  
/* new changes 11 1 2025 end */ 



/*sessions list 2*/
html, body{
	height:100%;
}
.calc-height{
    /*height: calc(100% - 350px);*/
	height:calc(100% - 238px);
    position: relative;
    top: -16px;
}

.desktop .calc-height{
	height:calc(100% - 50px);
}



.session-col {
    bottom: 0;
    left: 0;
    margin-bottom: 8px;
    overflow-x: auto;
    overflow-y: hidden;
    padding-bottom: 8px;
    padding-top: 2px;
    position: absolute;
    right: 0;
    scrollbar-color: #fff6 #00000026;
    scrollbar-width: auto;
    top: -2px;
    -webkit-user-select: none;
    user-select: none;
    white-space: nowrap;
}
.session-col-child{
	display: block;
    flex-shrink: 0;
    align-self: flex-start;
    padding: 0 6px;
    height: 100%;
    white-space: nowrap;
}
.session-card{
	display: flex;
    position: relative;
    box-sizing: border-box;
    flex-basis: 272px;
    flex-direction: column;
    flex-grow: 0;
    flex-shrink: 0;
    align-self: start;
    justify-content: space-between;
    width: 100%;
    max-height: 100%;
    padding-bottom: 8px;
    border-radius: 0px;
    background-color: var(--accent-background, var(--tr-background-list));
    /*box-shadow: var(--ds-shadow-raised, 0px 1px 1px #091e4240, 0px 0px 1px #091e424f);*/
    color: var(--accent-text, var(--ds-text-subtle, #44546f));
    vertical-align: top;
    white-space: normal;
    scroll-margin: 8px;
	border:none;
}

.session-card-body{
	display: flex;
    z-index: 1;
    flex: 1 1 auto;
    flex-direction: column;
    margin: 0 0px;
    padding: 0px 4px;
    overflow-x: hidden;
    overflow-y: auto;
    list-style: none;
    row-gap: 8px;
    -webkit-overflow-scrolling: touch;
    -webkit-transform: translate3d(0, 0, 0);
    scrollbar-color: var(--ds-background-neutral-hovered, #00000000) var(--ds-background-neutral, #091e420f);
	scrollbar-color: var(--ds-background-neutral-hovered, #000000a3) var(--ds-background-neutral, #091e420f);
    scrollbar-width: auto;
}
.swiper-container.sessions-new {
    height: 100%;
}
.sessionlist-header{
	font-weight:bold;
}

.session-card-body .fav-wrpr {
    position: absolute;
    right: 10px;
}

.session-card-body:hover {
    scrollbar-color: var(--ds-background-neutral-hovered, #000000a3) var(--ds-background-neutral, #091e420f);
    scrollbar-width: auto;
}
.session-members.speakers ul{
	scrollbar-width: none;
    max-height: 42px;
    min-height: 42px;
    margin-bottom: 0px;
}
.sessions-new .session-members.speakers ul{
	max-height: unset;
    min-height: unset;
}
.share-session:hover {
    color: #2f3685;
}
.session-members.speakers ul:hover{
	scrollbar-width: thin;
}
.session-col .scard-heading {
    min-height: unset;
	max-width: 94.5%;
}
.filter-search-wrpr.filter-pos {
    top: -34px;
    position: relative;
}
.session-col .session-members {
    margin-top: 5px;
}
.container.filter-search-wrpr.filter-pos .multicolorline {
    margin-top: 0;
}

.container.filter-search-wrpr.filter-pos label {
    display: none;
}

.container.filter-search-wrpr.filter-pos .search-wrpr {
    margin-top: 0px;
}
.session-col .session-members ul {
margin-bottom: 0px;}

.swiper-nav-custom-pos {
    position: absolute;
    top: -10px;
    right:23px;
    width: 110px;
    height: 26px;
    z-index:10;
}

.swiper-nav-custom-pos .swiper-button-prev {
    right: 0;
    width: 23px;
    height: 16px;
	top: 27px;
}

.swiper-nav-custom-pos .swiper-button-next {
    right: 0;
    width: 23px;
    height: 16px;
	top: 27px;
}

.swiper-nav-custom-pos .swiper-button-prev:before {
    content: "Prev Venue";
    position: absolute;
    top: 0px;
    left: 13px;
    color: #d83c34;
    font-size: 10px;
    line-height: 9px;
	text-transform: uppercase;
	font-weight: 600;
}
.swiper-nav-custom-pos .swiper-button-next:before {
    content: "Next Venue";
    position: absolute;
    top: 0px;
    right: 14px;
    color: #d83c34;
    font-size: 10px;
    line-height: 9px;
    text-align: right;
    text-transform: uppercase;
	font-weight: 600;
}
.swiper-nav-custom-pos .swiper-button-prev.swiper-button-disabled ,
.swiper-nav-custom-pos .swiper-button-next.swiper-button-disabled {
    opacity: 0;
	display:none;
}
.session-col .sponsoredby-title {
    display: flex;
    justify-content: center;
    width: 100%;
    padding-left: 0;
}
.delegate-only {
    background: #3ea254;
    color: #fff;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 1px;
    line-height: 21px;
    padding-left: 7px;
    padding-right: 7px;
    border-radius: 2px;
    position: absolute;
    right: 41px;
}
.speaker-details-wrpr .session-members.speakers ul {max-height: 61px;min-height: 61px;}

.speaker-details-wrpr .session-members.modarators {
    min-height: 46px;
}

.speaker-details-wrpr .card.session-card {
    background: #f2f1f1 !important;
}
.share-session {
    position: absolute;
    right: 14px;
    top: 45px;
    text-decoration: none;
    color: #d83c34;
}
.sessions-wrpr.sessions-home-wrpr{
	padding:40px;
}
.sessions-wrpr.sessions-home-wrpr h2 {
    text-transform: uppercase;
    font-weight: 600;
}
.sessions-wrpr.sessions-home-wrpr h2 a{
	color:#000;
	text-decoration:none;
}

.sessions-wrpr.sessions-home-wrpr .btn-yellow:hover {
    color: #000;
    box-shadow: 0 0 0 3px #ec842c;
}
.sessions-wrpr.sessions-home-wrpr .venue {
    padding-left: 0;
}
.sessions-wrpr.sessions-home-wrpr .sponsoredby-title {
    display: flex;
    justify-content: center;
    width: 100%;
    padding-left: 0;
}
.sessions-wrpr.sessions-home-wrpr  .delegate-only {
    right: 16px;
}

.sessions-wrpr.sessions-home-wrpr  .share-session {
    top: 13px;
    right: 41px;
    font-size: 16px;
}
.viewfull_prg {
    position: absolute;
    right: 13px;
    top: -25px;
    padding: 3px 18px !important;
	border-radius: 30px !important;	
}
.dear-friends {
    max-width: 60px;
}

.strengthening-card {
    padding: 35px 50px;
    border: none !important;
    background: #22234b !important;
    color: #fff;
    border-radius: 0px !important;
}

.strengthening-card p {
    margin-bottom: 0px;
    font-size: 22px;
}

.klf-list {
    list-style: none;
    padding-left: 0;	
}

.klf-list li {
    padding-left: 30px;
    background: url(../images/yellow-shape-2.png) no-repeat;
    background-size: 19px;
    line-height: 40px;
    margin-left: 0 !important;
}

.card.strengthening-card p {
    display: flex;
    align-items: center;
}

.card.strengthening-card p img {
    margin-right: 18px;
}

.innagupic {
    padding: 15px !important;
}

.calicutpic {
    padding: 15px !important;
}

.prev-page, .next-page {
    padding: 3px 10px 3px 5px;
    background: #f2f1f1;
    border: 1px solid #dadada;
    border-radius: 5px;
    position: relative;
    top: -10px;
}

.next-page {
    padding-right: 5px;
    padding-left: 10px;
}

.prev-page svg, .next-page svg {
    stroke: green;
}

.prev-page:hover, .next-page:hover {
    background:#fff;
}


/*sessions list 2 end*/
.btn-programme {
    padding-left: 15px !important;
    padding-right: 15px !important;
}

#downloadApp .modal-body {
    background: url(../images/aboutus-bg-mobile-pic.jpg) #0f1b24;
}

#downloadApp .app-popup-bg {
    background: url(../images/aboutus-bg-mobile-pic.jpg) center;
    background-size: cover;
}
#downloadApp .down-klfapp {
    display: flex;
    margin-bottom: 13px;
}

#downloadApp .down-klfapp img {
    margin-right: 16px;
}

#downloadApp .down-klfapp p {
    font-size: 21px;
    margin-bottom: 0px;
}

#downloadApp img.qr-code {
    max-width: 150px;
}



  
  
@media (max-width:1400px){
	.banner img {
		height: 406px;
		width: 100%;
		object-fit: cover;
	}
	.klf-drp {
		width: 250px;
	}
	.banner + .why-delegate .benefits-wrpr {
		margin-top: 93px;
	}
	
	.banner + .why-delegate h2.desktoponly {
		flex-direction: column;
	}

	.banner + .why-delegate h2.desktoponly span {
		margin-left: 0px;
	}
	
}

@media (max-width:1200px){
	.sessions-slide .col-md-3 {
		width: 33% !important;
	}
}
@media (max-width:1024px){
	.container-fluid.download-app{
		min-height:550px;
	}
	.desktop.Safari-16 .sessions-tabs .nav.nav-tabs a.nav-link {
		width: 59px;
		font-size: 13px;
		}
	.speakers-wrpr.speakers-list .col-md-3 {
		width: 50%;
	}
}

@media (max-width:1000px){
	.sessions-tabs .nav.nav-tabs:before {
		opacity: 0;
	}
	.calc-height {
		top: 20px;
	}
	.container-fluid.download-app .col-md-6 {
		text-align: center;
	}

	.container-fluid.download-app .col-md-6 .store-btn {
		margin-right: 20px;
	}
	
	.speaker-pic-and-details-wrpr .col-md-6 {
		width: 100%;
	}

	.speakers-wrpr.speaker-details-wrpr .col-md-1 {
		display:none;
	}
	.speakers-wrpr.speaker-details-wrpr .col-md-10 {
		width:100%
	}


	.speakers-wrpr.speakers-list .col-md-3 {
		width: 50%;
	}
	.floated-regbar.fixed-bottom.phoneonly {
		display: none !important;
	}
	.multicolorline{
		display:none;
	}
	.filter-wrpr.session-filter-wrpr:before {
		content: "Filter";
		display: flex;
		margin-top: 19px;
		margin-bottom: 8px;
		font-size: 22px;
		background: url(../images/multicolor-hline.jpg) no-repeat left bottom;
		background-size: 284px;
	}
	.filter-search-wrpr.active {
		left: 0px;
	}
	.filter-search-wrpr .filter-wrpr.session-filter-wrpr + .col-md-4 {
		width: 100%;
	}

	/* .filter-search-wrpr .filter-wrpr.session-filter-wrpr + .col-md-4 button {
		min-width: 80px;
	}
	.filter-search-wrpr {
		position: fixed;
		z-index: 10;
		background: #fff;
		width: 300px !important;
		height: auto;
		box-shadow: 0 0 10px color(srgb 0 0 0 / 0.18);
		left: -300px;
        padding-bottom: 30px;
		transition: all .2s linear !important;
      -o-transition: all .2s linear !important;
      -moz-transition: all .2s linear !important;
      -webkit-transition: all .2s linear !important;
	}
	.filter-search-wrpr.filter-pos {
		top: unset;
		position: fixed;
        z-index: 100;
	} */
	
	.filter-search-wrpr .row .col-md-3, .filter-search-wrpr .col-md-8 {
		width: 100%;
		margin-bottom: 30px;
	}

	.filter-search-wrpr label {
		display: block;
	}
	
	
	
	
	.sessions-slide .col-md-3 {
		width: 50% !important;
	}
	.whyDelegate-new-2 .col-md-4 .readmore-1 {
		bottom: 71px;
	}
	.whyDelegate-new-2 .container .row {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.whyDelegate-new-2 .container .row .whyshould {
		margin-top: 30px;
		margin-bottom: 30px;
	}
	.innerbanner.registration + .why-delegate + .container .col-md-5,
	.innerbanner.registration + .container .col-md-5 {
		width: 100%;
	}
	.first-row-pics {
		max-height: 318px;
	}
	.pic2 {
		height: 149px;
	}

	.pic3 {
		max-height: 171px;
	}

	.pic1 {
		min-height: 320px;
	}


	  
	 .phone-heading {
		display: flex !important;
		align-items: center;
	}

	.phoneonly.phone-heading h2 {
		margin-left: 19px;
	} 
	.stamp-card {
		zoom: .8;
	}
	.registration-type-popup .modal-dialog.modal-xl {
		min-width: 90%;
	}
	
	.aboutus-wrpr {
		background: none;
	}

	.aboutus-wrpr h2, .aboutus-wrpr p {
		color: #000;
	}

	.aboutus-wrpr {
		min-height: 340px;
	}
	.banner + .why-delegate .col-md-2:last-child {
		display: none;
	}

	.banner + .why-delegate .col-md-2 img {
		max-width: 100px;
		margin-bottom: 20px;
	}

	.banner + .why-delegate {
		padding-left: 15px;
	}
	.phone-nav ul li:last-child a {
		width: 96%;
		margin: 16px auto;
		text-align: center;
		min-height: 40px;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.phone-nav ul li:last-child {
		margin-left: 0 !important;
	}
	.speakers-wrpr {
		padding:80px 15px
	}
	.card.session-card{
		box-shadow:0px 0px 5px color(srgb 0 0 0 / 0.48);
	}
	.banner img {
		height: auto;
	}
	.klf-counts-wrpr .col-md-2 {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-bottom: 0px;
	}

	#counters_2 .col-md-4 {
		margin-bottom: 0px;
	}
	.testimonials-wrpr h2, 
	.festival-partners-wrpr h2,
	.photogallery-wrpr h2,
	.news-wrpr h2,
	.videos-wrpr h2, 
	.sessions-wrpr h2,
	.speakers-wrpr h2	{
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	.testimonials-wrpr h2 span,
	.festival-partners-wrpr h2 span,
	.photogallery-wrpr h2 span,
	.news-wrpr h2 span,
	.videos-wrpr h2 span,
	.sessions-wrpr h2 span,
	.speakers-wrpr h2 span	{
		margin: 20px 0;
	}

	.testimonials-wrpr h2 span + span,
	.festival-partners-wrpr h2 span + span,
	.photogallery-wrpr h2 span + span,
	.news-wrpr h2 span + span,
	.videos-wrpr h2 span + span,
	.sessions-wrpr h2 span + span,
	.speakers-wrpr h2 span + span	{
		display: none;
	}
	.footer-menu-wrpr {
		flex-direction: column;
        margin-bottom: 60px;
	}
	.testimonials-wrpr .swiper-pagination-wrpr,
	.photogallery-wrpr .swiper-pagination-wrpr,
	.videos-wrpr .swiper-pagination-wrpr,
	.news-wrpr .swiper-pagination-wrpr{
		margin-top:35px;
	}
	.footer-logo-wrpr {
		flex-direction: column;
	}

	.footer-logo-wrpr img {
		max-width: 100%;
	}
	
	.banner h1 span:before {
		content: "";
		position: absolute;
		display: inline-flex;
		width: 78px;
		height: 23px;
		top: 43px;
		background-size: cover;
		right: 20px;
	}
	.highlight.highlight-green, .highlight.highlight-blue, .highlight.highlight-gray {
		padding-bottom: 0px;
		padding-top: 40px;
	}
	.highlight-columns .col-md-6 {
		border-right: none !important;
	}
	.footer-section {
		text-align: center;
	}

	.footer-logo-cntnr {
		max-width: 100%;
		margin-bottom: 40px;
	}

	.footer-menu-cntnr {
		margin-bottom: 30px;
	}

	.footer-btn-cntnr {
		display: flex;
		flex-direction: column;
	}

	.footer-copy .col-md-12 {
		display: flex;
		justify-content: center;
	}
	.requestdemo-cntnt {
		text-align: center;
	}
	.login-screen {
		display: none;
	}
	.highlight.highlight-blue .container > .row {
		display: flex;
	}

	.highlight.highlight-blue .container > .row .col-md-6:first-child {
		order: 2;
	}

	.highlight.highlight-green:after, .highlight.highlight-blue:after, .highlight.highlight-gray:after{
		display:none;
	}
	

	.phoneonly {
		display: block !important;
	}
		  
    .desktoponly{
        display:none;
    }
	
	  .btn-groupregister.desktoponly {
		display: none !important;
	}

	
	
	/* menu toggle button */
	.navbar .nav-item:nth-child(7), .navbar .nav-item:nth-child(6) {
		padding-top: 7px;
		padding-bottom: 7px;
	}
	.navbar .nav-item {
			margin-left: 15px;
			border-bottom: 1px solid #dadada;
	}
    .phone-nav ul {
        width: 100%;
    }
	
	.navbar>.container-fluid.menu-wrpr {
        justify-content: unset;
    }
	.phone-nav {
        display: flex;
        width: 100%;
        background: #fff;
        position: absolute;
        left: 0;
        top: 80px;
        justify-content: flex-start;
        align-items: f;
    }
	.navbar-toggler {
        background: none;
        border: none;
    }
	button.navbar-toggler:focus {
		box-shadow: none;
	}	
		button.navbar-toggler {
		position: absolute;
		right: 0;
		top:23px;
        border: none
	}

	#collapsingNavbar {
		order: 2;
	}
	.navbar-toggler-icon {
        background: none !important;
        cursor: pointer;
        display: block;
        height: 50px;
        padding: 0;
        position: absolute;
        right: 10px;
        top: -2px;
        width: 50px !important;
        z-index: 400;
	}
	.navbar-toggler-icon span {
		position: absolute;
		top: 50%;
		left: 10px;
		right: 10px;
		display: block;
		height: 4px;
		margin-top: -2px;
		background-color: #314593;
		font-size: 0px;
		-webkit-touch-callout: none;
		-webkit-user-select: none;
		-khtml-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
		-webkit-transition: background-color 0.2s;
		transition: background-color 0.2s;
	}
	.navbar-toggler-icon span:before, .navbar-toggler-icon span:after {
		position: absolute;
		left: 0;
		width: 100%;
		height: 100%;
		background: #314593;
		content: '';
		-webkit-transition: -webkit-transform 0.3s;
		transition: transform 0.3s;
	}
	.navbar-toggler-icon span:before {
		-webkit-transform: translateY(-220%);
		transform: translateY(-220%);
	}
	.navbar-toggler-icon span:after {
		-webkit-transform: translateY(220%);
		transform: translateY(220%);
	}
	.navbar-toggler-icon.active span:before {
		-webkit-transform: translateY(0) rotate(45deg);
		transform: translateY(0) rotate(45deg);
	}
	.navbar-toggler-icon.active span:after {
		-webkit-transform: translateY(0) rotate(-45deg);
		transform: translateY(0) rotate(-45deg);
	}
	span.navbar-toggler-icon.active span {
		background: transparent;
	}
	
	
	/* menu toggle button end */
	
	
	
	
	
	
   
   .kf-subpic {
		max-width: 110px;
   }
   .requestdemo-cntnt {
		padding-left: 0;
		padding-right: 0;
	}
	.requestdemo-section .col-md-6:last-child {
		flex-direction: column;
	}

	.requestdemo-section .col-md-6:last-child a + a {
		margin-left: 0;
		margin-top: 11px;
	}
	
}



@media (max-width:950px){
	.festival-partners-wrpr .col-md-12 .col-md-2 {
		width: 33%;
	}
	.videos-wrpr .swiper-rightarrow {
		top: 176px;
	}
	
	.testimonials-listing .col-md-3 {
		width: 30%;
	}

	.testimonials-listing .testimonials-slide {
		display: flex;
		justify-content: center;
	}
	
	
	a.nav-link.pl-0.reqdemo.phoneonly.phone-reg {
		line-height: 31px;
		text-align: center;
		position: relative;
		left: 12px;
		order: 2;
	}
	.why-delegate.wdhome .col-md-6 {
		width: 100%;
	}
	
 
	.delegate-form .form-group .col-md-6.mt-5 {
		margin-top: 0px !important;
	}
	.delegate-register-wrpr {
		margin-bottom: 19px;
	}
	.benefits-wrpr {
		margin-bottom: 40px;
	}
	.delegate-form .form-group .col-md-6 {
		margin-top: 25px;
	}

	.delegate-form .form-group .row.mt-5 {
		margin-top: 0px !important;
		margin-bottom: 20px;
	}

	.delegate-form .form-group .row.mt-5 .col-md-4 {
		margin-top: 20px;
	}

	.registration + .container .col-md-7, .registration + .container .col-md-4 ,
	.why-delegate + .container .col-md-7, .why-delegate + .container .col-md-4 {
		width: 100%;
		padding-bottom: 0px !important;
		padding-top: 0px !important;
	}

	.registration + .container .col-md-1 ,
	.why-delegate + .container .col-md-1 {
		display: none;
	}
	
}


@media (max-width:768px){
	#downloadApp .modal-body {
		padding: 15px;
	}

	.down-klfapp {
		flex-direction: column;
	}
    .filter-search-wrpr.filter-pos {
        top: 0px;
        position: relative;
    }
	.festival-partners-wrpr .col-md-12 .col-md-2 {
		width: 33%;
	}
	.googlemap-container img {
		width: 100%;
		height: 300px;
		object-fit: cover;
		object-position: 71%;
	}
	.speaker-details-banner.innerbanner h2 {
		zoom: .8;
	}
	.speakers-wrpr.speaker-details-wrpr .card {flex-direction: column; background-size: contain; padding: 0px;}

	.speakers-wrpr.speaker-details-wrpr .col-md-10 {
		width: 100%;
	}
	
	.btn-groupregister.phoneonly {
		display: flex !important;
        justify-content: center;
        align-items: center;
	}

	.btn-groupregister.phoneonly i {
		margin-left: 8px;
	}
	.groupreg-btn-wrpr .btn-groupregister {
		border-radius: 4px !important;
		border-color: transparent !important;
		margin-top: 10px;
	}
	.gender-wrpr, .age-wrpr {
		margin-bottom: 6px;
	}
	.col-md-4.district-wrpr, .col-md-4.state-wrpr, .col-md-4.regbtn-wrpr {
		width: 33.33333333% !important;
	}
	.gender-wrpr, .age-wrpr {
		padding-top: 26px;
	}
	.whyDelegate-new-2 .col-md-4.whyshould {
		margin-bottom: 50px;
	}
	.whyDelegate-new-2 .col-md-4.regfree .readmore-1 {
		bottom: 141px;
	}
	
	.testimonials-listing .col-md-3 {
		width: 50%;
	}

	
	
	.testimonials-listing  .card.testimonial-card {
		max-width: 100%;
		width: 100%;
		min-width: 100%;
	}

	.testimonials-listing .testim-user {
		max-width: 100%;
	}

	.testimonials-listing .card.testimonial-card .testim-cntnt {
		max-width: 100%;
	}
	
	.why-delegate.whyDelegate-new .col-md-6 {
		background-position: right 90%, 0px 97%;
	}
	
	
	/* new media changes  6 11 2024 */
	
	.videos-wrpr .videos-wrpr {
		padding: 15px !important;
	}
	
	/* new media changes  6 11 2024 */
	
	
	
	/* new media changes  5 11 2024 */
	.session-filter-wrpr {
		
	}
	.session-filter-wrpr.active {
		
	}
	.sessions-tabs {
		top: 22px;
	}
	.sessions-tabs .nav.nav-tabs a.nav-link {
		padding-left: 9px;
        padding-right: 9px;
        font-size: 15px;
        display: flex;
        flex-direction: column;
        position: relative;
        width: 60px;
	}
	.sessions-tabs .nav.nav-tabs a.nav-link sup {
		position: absolute;
		right: 5px;
		top: 12px;
	}
	
	.terms-and-button .col-md-6 {
		width: 50%;
	}

	.terms-and-button .col-md-6:last-child {
		display: flex;
		align-items: self-end;
	}
	
	.field-groups .col-md-6 {
		margin-bottom: 20px !important;
		margin-top: 0px !important;
	}
	.field-groups .col-md-6 {
		width: 50%;
	}
	
	
	/* new media changes 5 11 2024 */
	
	.speaker-details-wrpr 
	 .speaker-Session-cntnr {
		order: 1;
		border-bottom: 1px solid #dadada;
		margin-bottom: 20px;
		border-top: 1px solid #dadada;
		padding-top: 20px;
		text-align: center;
	}

	.speaker-details-wrpr .col-md-3 {
		order: 1;
	}

	.speaker-details-wrpr .col-md-6 {
		order: 3;
	}
	
	
	
	.regfree {
		padding-bottom: 80px;
	}
	 .why-delegate.wdhome:before {
		right: 0;
		width: 100%;
		bottom: unset;
		height: 49%;
	}
	.footer-logo-wrpr img {
        max-width: 100%;
        margin-bottom: 40px;
	}
	.dc-cklf .col-md-6:nth-child(1) .content-wrpr {
		padding: 10px 60px 0px 50px;
	}
	.dc-cklf .col-md-6:nth-child(2) .content-wrpr {
		padding: 10px 60px 0px 50px;
	}
	.third-row-pics .logo-strips {
		margin-bottom: 20px;
		margin-top: 10px;
	}

	.klf-venue .card img {
		margin-bottom: 30px;
		margin-top: 30px;
	}

	.dc-cklf:before {
		width: 100%;
		height: 46%;
		bottom: unset;
	}

	.dc-cklf .col-md-6:nth-child(2) {
        padding-top: 120px;
    }
	.pic3 {
        max-height: 371px;
    }
	.pic2 {
        height: 340px;
        object-position: 0 -140px;
    }
	.first-row-pics {
        max-height: unset;
    }
	.footer-cntnt-wrpr {
		margin-top: 50px;
	}
	.banner + .why-delegate h2 span {
		margin-left: 0px;
        display: block;
	}
	.pay-col-1,  .pay-col-3 {
		display:none;
	}
	.delegate-form .form-group .col-md-6 {
		margin-top: 25px;
	}

	.delegate-form .form-group .row.mt-5 {
		margin-top: 0px !important;
		margin-bottom: 0px;
	}

	.delegate-form .form-group .row.mt-5 .col-md-4 {
		margin-top: 20px;
	}
	.or-cntnr {
		display: none;
	}
	.footer-cntnt-wrpr {
		margin-top: 0px;
	}
}


@media (max-width:500px){
	#downloadApp .modal-dialog {
		position: absolute;
		bottom: 0;
	}
	
	#downloadApp .modal-header {
		display: none;
	}

	#downloadApp .modal-body button[data-bs-dismiss=modal] {
		position: absolute;
		color:#fff;
		right:10px;
		top:10px;
	}

	#downloadApp .qr-code{
		display:none;
	}

	#downloadApp .down-klfapp img{
		display:none;
	}

	#downloadApp .col-md-6 {
		width:49%
	}
	#downloadApp p {
		font-size:16px !important;
		display:inline !important;
	}

	#downloadApp .down-klfapp {
		width:90%;
	}

	#downloadApp .modal-body {
		background:#2f3685;
	}
	
	
	
	
	
	
	
	
	
	#install-app button[aria-label=Close] {
		position: absolute;
		right: 14px;
		top: 0px;
		color: #fff;
	}
	#install-app .modal-dialog {
		position: absolute;
		bottom: 0;
	}
	.strengthening-card {
		padding: 15px !important;
	}

	.strengthening-card .col-md-4.ps-0 {
		padding-left: 15px !important;
	}

	.strengthening-card .col-md-8 p {
		margin-bottom: 12px !important;
		font-size: 19px;
		margin-top: 11px;
	}

	.strengthening-card li {
		line-height: 30px;
		margin-bottom: 18px;
	}
	.dearfreiends-wrpr {
		padding-right: 15px !important;
	}

	.dearfreiends-wrpr + .col-md-2 img {
		width: 100%;
	}

	.strengthening-card p {
		display: flex;
		flex-direction: column;
	}
	.col-md-6.pe-5.my-auto.dearfreiends-wrpr {
		padding-right: 15px !important;
	}

	.col-md-6.my-auto.ps-5.klf-has-wrpr {
		padding-left: 15px !important;
	}
    .col-md-12.filter-wrpr.session-filter-wrpr .col-md-2 {
        width: 49%;
    }
	.festival-partners-wrpr .col-md-12 .col-md-2 {
		width: 49%;
	}
	.speakers-wrpr.speakers-list .col-md-3 {
		width: 100%;
	}
    .session-banner.innerbanner {
        min-height: 35px;
        display: flex;
        align-items: center;
        padding-bottom: 42px;
        padding-top: 12px;
    }
	.sessions-tabs .nav.nav-tabs {
		border-bottom: none;
		position: absolute;
		bottom: 15px;
	}
	.floated-regbar.fixed-bottom.phoneonly {
		display: flex !important;
	}
	.sessions-slide .col-md-3 {
		width: 100% !important;
	}

	.innerbanner h2 + p {
		font-size: 13px;
	}
	.col-md-4.district-wrpr, .col-md-4.state-wrpr {
		width: 50% !important;
	}

	.col-md-4.regbtn-wrpr.text-end {
		width: 100% !important;
		text-align: center !important;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.gender-wrpr, .age-wrpr {
		width: 50% !important;
	}
	.videos-wrpr .swiper-rightarrow {
		position: absolute;
		top: 190px;
		right: 0;
	}
	.speakers-wrpr .col-md-10 {
		width: 80%;
	}

	.speakers-wrpr .col-md-1 {
		width: 10%;
	}	

	.news-wrpr .swiper-rightarrow {
		top: 217px;
	}
	.photogallery-wrpr .swiper-rightarrow {
		top: 124px;
	}
	.whyDelegate-new-2 .col-md-4.regfree .readmore-1 {
		bottom: 146px;
	}
	.whyDelegate-new-2 .col-md-4 .register {
		bottom: 77px;
	}

	.whyDelegate-new-2 .col-md-4.whyshould {
		margin-bottom: 60px;
	}

	.whyDelegate-new-2 .col-md-4.beStudent .register {
		bottom: -20px;
	}
	
	.testimonials-listing .col-md-3 {
		width: 100%;
	}
	
	.festival-partners-wrpr .col-md-12 .row {
		padding-left: 10px;
		padding-right: 10px;
	}
	
	
	button.navbar-toggler{
		top:16px;
	}
	.footer-copy .col-md-12 .archives {
		position: static;
	}

	.footer-copy .col-md-12 span.copy {
		position: static;
	}
	.footer-copy .col-md-12 {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding-top: 30px;
		line-height: 27px;
	}

	.footer-copy .col-md-12 .copy {
		order: 3;
	}

	.footer-copy .col-md-12 a {
		order: 3;
	}

	.footer-copy .col-md-12:before {
		display: none;
	}

	.footer-copy .col-md-12 span:not(copy) {
		order: 4;
	}
	
	
	
	.benefits-wrpr thead td {
		height: 69px;
	}
	.innerbanner + .why-delegate {
    padding-left: 20px;
	}
	.dele-perks strong {
		font-size: 23px;
	}
	.benefits-wrpr thead tr td:nth-child(2) span, 
	.benefits-wrpr thead tr td:nth-child(3) span, 
	.benefits-wrpr thead tr td:nth-child(4) span{
		rotate: -60deg;
		display:block;
		padding-left:5px;
		letter-spacing:.4px;
	}

	.benefits-wrpr thead tr td:nth-child(2),
	.benefits-wrpr thead tr td:nth-child(3),
	.benefits-wrpr thead tr td:nth-child(4){
		font-size: 12px;
	}
	.benefits-wrpr tbody tr td {
		word-wrap: break-word;
	}
	.klf-drp {
		width: 190px;
	}
	.navbar-brand img {
		max-width: 150px;
	}
	.phone-reg {
		line-height: 31px;
		text-align: center;
		position: relative;
        left: 12px
	}
	.dc-cklf .col-md-6:nth-child(2) {
       padding-top: 80px;
	}
	.innerbanner h2 span {
		margin-left: 7px;
	}
	.footer-logo-wrpr img {
        max-width: 260px;
        margin-bottom: 40px;
    }
	.content-wrpr {
		padding: 10px 60px 0px 90px !important;
	}

	.dc-cklf {
		padding: 40px 0px;
	}
	.overview-circle {
		width: 160px !important;
		height: 160px !important;
		border-radius: 40px;
	}

	.overview-text {
		margin-bottom: 29px;
	}

	.klf-photos {
		padding: 25px 0px;
	}
	.festival-on-beach .row.mt-5.pt-5 {
		padding-top: 0px !important;
		margin-top: 10px !important;
	}
	.festival-on-beach {
		padding: 21px 15px;
	}
	
	.phone-nav{
		top:80px;
	}
	.kizhakemuri img {
		margin-bottom: 19px;
	}

	.kizhakemuri .col-md-2 {
		text-align: center;
	}

	.klf-venue .card .col-md-4 img {
		margin-bottom: 30px;
	}
	.footer-copy {
		margin-bottom: 30px;
	}
	.floated-regbar {
		background: #2f3584;
		padding: 7px;
		display: flex !important;
		justify-content: center;
		align-items: center;
	}

	.floated-regbar a {
		border-radius: 30px;
		min-width: 140px;
		background: #e23332;
		border: none;
	}
	.testimonials-wrpr {
		padding: 50px 0;
	}
	.festival-partners-wrpr {
		padding: 50px 0;
	}
    .photogallery-wrpr {
		padding-top: 50px;
	}
    .klf-counts-wrpr .col-md-2 {
        margin-bottom: 60px;
    }
	#counters_2 .col-md-4 {
        margin-bottom: 50px;
    }
	.news-wrpr {
    	padding: 60px 0;
	}

    .needhelp {
        display: flex;
        justify-content: flex-end;
        position: relative;
        top: -39px;
    }
    
    .needhelp i {
        position: relative;
        left: -9px;
        top: 2px;
        color: #f9cd26;
    }
	
}