/* Variables */
:root {
    --primary-color: #4F46E5;
    --primary-light: #818CF8;
    --primary-dark: #4338CA;
    --accent-color: #F472B6;
    --success-color: #10B981;
    --bg-gradient: linear-gradient(135deg, #4F46E5, #F472B6);
    --message-user: linear-gradient(135deg, #4F46E5, #818CF8);
    --message-bot: #f3f4f6;
    --shadow-sm: 0 2px 4px rgba(0, 0, 0, 0.1);
    --shadow-md: 0 4px 6px rgba(0, 0, 0, 0.1);
    --shadow-lg: 0 10px 15px rgba(0, 0, 0, 0.1);
}

/* Container Styles */
.chatbot-container {
    position: fixed;
    bottom: 45px;
    right: 30px;
    z-index: 2000;
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.chatbot-container.expanded {
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100vh;
}

/* Chat Button Styles */
.btn-chatbot {
    background: var(--bg-gradient);
    height: 55px;
    width: 55px;
    border: none;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: var(--shadow-lg);
}

.btn-chatbot {
    transform: scale(1.1); /* Always apply the scale */
    box-shadow: 0 15px 20px rgba(0, 0, 0, 0.2); /* Always apply the shadow */
    transition: transform 0.3s ease, box-shadow 0.3s ease; 
}

.btn-chatbot:hover {
    transform: scale(1.1);
    box-shadow: 0 15px 20px rgba(0, 0, 0, 0.2);
}

/* Chat Content Styles */
.chatbot-content {
    position: fixed;
    bottom: 10px;
    right: 10px;
    z-index: 2000;
    width: min(400px, 95vw);
    height: min(600px, 90vh);
    background-color: #fff;
    border-radius: 20px;
    box-shadow: var(--shadow-lg);
    display: flex;
    flex-direction: column;
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    border: 1px solid rgba(79, 70, 229, 0.1);
}

.chatbot-container.expanded .chatbot-content {
    width: 100%;
    height: 100vh;
    bottom: 0;
    right: 0;
    border-radius: 0;
}

/* Header Styles */
.chatbot-header {
    min-height: 70px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 20px;
    background: var(--bg-gradient);
    border-radius: 20px 20px 0 0;
    color: white;
}

.chatbot-container.expanded .chatbot-header {
    border-radius: 0;
}

.chatbot-controls {
    display: flex;
    align-items: center;
    gap: 15px;
}

.chatbot-title {
    display: flex;
    align-items: center;
    gap: 12px;
}

.chatbot-title .chatbot-name {
    font-size: clamp(16px, 2.5vw, 18px);
    font-weight: 600;
    display: flex;
    align-items: center;
    gap: 8px;
    color: white;
}

.chatbot-name .chatbot-beta {
    font-size: 12px;
    background-color: var(--accent-color);
    color: white;
    padding: 2px 8px;
    border-radius: 20px;
    font-weight: 500;
}

.chatbot-header button {
    background-color: rgba(255, 255, 255, 0.2);
    border: none;
    cursor: pointer;
    padding: 8px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s ease;
    color: white;
}

.chatbot-header button:hover {
    background-color: rgba(255, 255, 255, 0.3);
    transform: scale(1.05);
}

/* Body Styles */
.chatbot-body {
    flex: 1;
    padding: 20px;
    overflow-y: auto;
    font-size: clamp(13px, 2vw, 14px);
    font-weight: 500;
    background-color: #fff;
    scroll-behavior: smooth;
}

.chatbot-body::-webkit-scrollbar {
    width: 6px;
}

.chatbot-body::-webkit-scrollbar-track {
    background: transparent;
}

.chatbot-body::-webkit-scrollbar-thumb {
    background: var(--primary-light);
    border-radius: 10px;
}

.chatbot-body::-webkit-scrollbar-thumb:hover {
    background: var(--primary-color);
}

/* Message Styles */
.chatbot-message {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.chatbot-message .message {
    max-width: 100%;
    animation: messageAppear 0.3s ease;
}

@keyframes messageAppear {
    from {
        opacity: 0;
        transform: translateY(10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.chatbot-message .message.right {
    color: #fff;
    display: flex;
    justify-content: flex-end;
}

.chatbot-message .message.right .message-content {
    max-width: 85%;
    border-radius: 18px 18px 0 18px;
    padding: 12px 18px;
    background: var(--message-user);
    word-wrap: break-word;
    box-shadow: var(--shadow-sm);
}

.chatbot-message .message.left {
    color: #374151;
    display: flex;
    justify-content: flex-start;
}

.chatbot-message .message.left .message-content {
    max-width: 85%;
    border-radius: 18px 18px 18px 0;
    padding: 12px 18px;
    background-color: var(--message-bot);
    word-wrap: break-word;
    box-shadow: var(--shadow-sm);
}

/* Footer Styles */
.chatbot-footer {
    min-height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 20px;
    background-color: #fff;
    border-top: 1px solid rgba(79, 70, 229, 0.1);
    gap: 15px;
    border-radius: 0 0 20px 20px;
}

.chatbot-container.expanded .chatbot-footer {
    border-radius: 0;
}

.chatbot-footer input {
    flex: 1;
    height: 45px;
    border: 2px solid rgba(79, 70, 229, 0.2);
    border-radius: 25px;
    padding: 0 20px;
    font-size: 14px;
    transition: all 0.2s ease;
    background-color: #f9fafb;
}

.chatbot-footer input:focus {
    border-color: var(--primary-color);
    background-color: #fff;
    outline: none;
}

.chatbot-footer input::placeholder {
    color: #9CA3AF;
}

.chatbot-footer button {
    background: var(--primary-color);
    height: 45px;
    width: 45px;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s ease;
    box-shadow: var(--shadow-sm);
}

.chatbot-footer button:hover {
    background: var(--primary-dark);
    transform: scale(1.05);
}

/* Loading Animation */
.typing-indicator {
    display: flex;
    gap: 5px;
    padding: 8px 16px;
    background: var(--message-bot);
    border-radius: 15px;
    width: fit-content;
}

.typing-indicator span {
    width: 8px;
    height: 8px;
    background: var(--primary-color);
    border-radius: 50%;
    animation: typing 1.4s infinite ease-in-out;
}

.typing-indicator span:nth-child(1) { animation-delay: 0.2s; }
.typing-indicator span:nth-child(2) { animation-delay: 0.3s; }
.typing-indicator span:nth-child(3) { animation-delay: 0.4s; }

@keyframes typing {
    0%, 100% { transform: translateY(0); }
    50% { transform: translateY(-5px); }
}

/* Responsive Design */
@media screen and (max-width: 480px) {
    .chatbot-container {
        bottom: 20px;
        right: 20px;
    }
    
    .btn-chatbot {
        height: 50px;
        width: 50px;
    }
    
    .chatbot-content {
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
        border-radius: 0;
    }
    
    .chatbot-header {
        border-radius: 0;
        padding: 12px 15px;
    }
    
    .chatbot-footer {
        border-radius: 0;
        padding: 12px 15px;
    }
}

/* Dark Mode Support */
@media (prefers-color-scheme: dark) {
    :root {
        --message-bot: #2D3748;
    }

    .chatbot-content {
        background-color: #1A202C;
        border-color: rgba(255, 255, 255, 0.1);
    }

    .chatbot-body {
        background-color: #1A202C;
    }

    .chatbot-footer {
        background-color: #1A202C;
        border-color: rgba(255, 255, 255, 0.1);
    }

    .chatbot-message .message.left {
        color: #E2E8F0;
    }

    .chatbot-footer input {
        background-color: #2D3748;
        border-color: rgba(255, 255, 255, 0.1);
        color: #E2E8F0;
    }

    .chatbot-footer input::placeholder {
        color: #718096;
    }

    .chatbot-footer input:focus {
        border-color: var(--primary-light);
        background-color: #2D3748;
    }
}